import moment from 'moment';
import _ from 'lodash'

export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return parts[0];
}
export const formateDate=(dateValue,dateFormate)=>{
        return moment(dateValue).format(dateFormate)
}

export const appendUid = (route, uid) => {
        if (uid) {
            let val = `${route}?uid=${uid}`;
            return val;
        }
        else return route;
    }
    
export const findIndex=(array, id) => {
    let index = _.findIndex(array,id);
    return index;
}
export const pointsCalculator=(isHometeamPredicted,isAwayTeamPredicted, matchDetails) => {
    let points=0,matchResult='W';
    if(isHometeamPredicted && isAwayTeamPredicted){
        if(matchDetails.goalsHomeTeam===matchDetails.goalsAwayTeam){
            points=100;
            matchResult="W"
        }else{
            points=0
            matchResult="L"
        }
    }else if(isHometeamPredicted){
       if(matchDetails.goalsHomeTeam>matchDetails.goalsAwayTeam){
            points=100;
            matchResult="W"
       }else if(matchDetails.goalsHomeTeam<matchDetails.goalsAwayTeam){
            points=0
            matchResult="L"
       }else{
           points=50;
           matchResult="W"
       }
   }else if(isAwayTeamPredicted){
        if(matchDetails.goalsHomeTeam<matchDetails.goalsAwayTeam){
            matchResult="W"
            points=100;
    }else if(matchDetails.goalsHomeTeam>matchDetails.goalsAwayTeam){
            matchResult="L"
            points=0
    }else{
        points=50;
        matchResult="W"
    }
   }
    return {points,matchResult};
}

export const getLatestUpcomingMatch=(matchesList)=>{
    let parsedMatchesLsit;
        parsedMatchesLsit=matchesList.filter((data)=>{
            return (data.statusShort === 'NS')
        })

        console.log("parsedMatchesLsit",parsedMatchesLsit[0])
    return parsedMatchesLsit[0]
}

export const createPredictionPointData=(matchList,predictedData)=>{
    let predictedPointData=[];
    matchList.forEach(matchDetails => {
            let isHomeTeamPredicted = findIndex(predictedData, { predicated_team_one_id: matchDetails.homeTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
            let isAwayTeamPredicted = findIndex(predictedData, { predicated_team_two_id: matchDetails.awayTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
            if(isAwayTeamPredicted || isHomeTeamPredicted){
                let hwin = matchDetails.goalsHomeTeam > matchDetails.goalsAwayTeam ? true : false;
                let awin = matchDetails.goalsHomeTeam < matchDetails.goalsAwayTeam ? true : false;
                let draw = matchDetails.goalsHomeTeam == matchDetails.goalsAwayTeam ? true : false;
                if(draw){
                    predictedPointData.push({matchid:matchDetails.fixture_id,winning_team_id:-1})
                }else if(hwin){
                    predictedPointData.push({matchid:matchDetails.fixture_id,winning_team_id:matchDetails.homeTeam.team_id})
                }else if(awin){
                    predictedPointData.push({matchid:matchDetails.fixture_id,winning_team_id:matchDetails.awayTeam.team_id})
                }

            }
   
        });
        return predictedPointData
}

export const parseQueryString = (url) => {
    var urlParams = {};
    url.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function($0, $1, $2, $3) {
            urlParams[$1] = $3;
        }
    );

    return urlParams;
};
export const getUid = () => {
    const href = window.location.href;
    let uidIndex=href.indexOf('uid');
    let uid;
    if(uidIndex>=0){
        uid=href.substring(uidIndex+4,href.length)
        return uid;
    }else{
        return null
    }
}
