import * as action_type from './../actions/leaderboard/action_types';
const initialState = {
        leaderboardData:[]
}
const LeaderBoardReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.GET_LEADERS_POINT_FETCH_SUCCESS:
            return Object.assign({}, state, {
                leaderboardData: action.data.data
            });
        default:
            return state;
    }
}

export default LeaderBoardReducer;