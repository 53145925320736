import React, { useState } from 'react'
import './accordion.scss'
const Accordion = (props) => {
  const [show, setShow] = useState(false)
  const { question, ans } = props.subscriptionInstruct
  return (
    <div className='profile-subscribe-info-section' onClick={()=>setShow(!show)}>
        <div className='profile-subscribe-title'>
          <h1>{ question}</h1>
          <img src={show ? require('./../../assets/images/icons/arrow-up-align.png') :require('./../../assets/images/icons/arrow-down-align.png')}/>
        </div>
        {show && <p className='subscribe-ans'>{ans}</p>}
    </div>
  )
}

export default Accordion