import * as action_type from './../actions/calendar/action_types';
import { addOrReplace } from './../../utils/dataParser'
import { CalendarSaga } from '../redux-saga/calendarSaga';

const initialState = {
    matchesList: [],
    predictedData: [],
    isModalOpen:true,
}
const CalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.GET_MATCH_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                matchesList: action.data.api.fixtures,
                isFetching:false
            });
        case action_type.ADD_PREDICTION:
        console.log("action.data.matchid",action.data.matchid)
            let parsedData = addOrReplace(state.predictedData, { matchid: action.data.matchid }, action.data,action.otherData);
            console.log("parsedData", parsedData)
            return Object.assign({}, state, {
                predictedData: parsedData
            });
        case action_type.GET_PREDICTION_SUCCESS:
                console.log("PredictedData",action.data)
            return Object.assign({}, state, {
                predictedData: action.data
            });
        case action_type.CLOSE_MODAL:
            return Object.assign({}, state, {
                isModalOpen: false
            });
        case action_type.OPEN_MODAL:
            return Object.assign({}, state, {
                isModalOpen: true
            });
        default:
            return state;
    }
}

export default CalendarReducer;