//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,SimpleDropDown,ErrorMessage} from './../form-elements';
import ComponentWrapper from './componentWrapper';
import { FormattedMessage } from 'react-intl';
 import loginWrapper from './loginHOC'

import Loader from './../core/loader/loader';
import './login.scss';

const Team = ({handleRouting,countryList,handleChange,fields,errors,isSaving}) =>{ 
    console.log("fields['team']",fields['team'])
    let defaultValue=fields['team'] && fields['team'].value!==null? fields['team']:'';
    return(
    <ComponentWrapper heading={<FormattedMessage id='team' defaultMessage='Team'/>} subheading={<FormattedMessage id='select_your_team_right_here' defaultMessage='Select your team right here'/>}>
        <div className="login-form">
            <SimpleDropDown
                type="text"
                name="team"
                value={defaultValue}
                onChange={handleChange}
                placeholder={
                    <FormattedMessage id='team_dropdown_placeholder' defaultMessage="Select your team"/>
                } 
                options={countryList}
                IconComponent={
                        ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                    }
            />
            <ErrorMessage errorMessage={errors['team']}/>
            <Simplebutton onClick={()=>handleRouting('/')} isDisabled={isSaving}
             text={isSaving ? null :<FormattedMessage id='continue' defaultMessage='Continue'/>} isPrimary={false} customStyle={'login-inputbox'}>
            {isSaving ? <Loader customStyle={'loader-size'}/>:   
                <img className="login-icon" src={require('./../../assets/images/icons/right-arrow.svg')} alt="login-icon"/>
                }
            </Simplebutton>
        </div>
    </ComponentWrapper>
)};

Team.defaultProps={
}

Team.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
    countryList:PropTypes.array,   
}

export default Team;