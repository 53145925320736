
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import './calendar.scss';
import { formateDate, findIndex,appendUid,getUid } from './../../utils/helper';
import { teamShortHandName} from './../../utils/dataParser';
import { FormattedMessage } from 'react-intl';


const UpcomingMatchCard = ({ matchDetails,handlePrediction, predicatedData,isEditable,selectedLanguage }) => {
    let isHomeTeamPredicted = findIndex(predicatedData, { predicated_team_one_id: matchDetails.homeTeam.team_id,matchid:matchDetails.fixture_id }) >= 0 ? true : false;
    let isAwayTeamPredicted = findIndex(predicatedData, { predicated_team_two_id: matchDetails.awayTeam.team_id,matchid:matchDetails.fixture_id }) >= 0 ? true : false;
    return (
        <div className="match-card upcoming-match-card-padding" key={"previpus-matches+" + matchDetails.fixture_id}>
            <div className="match-date">
                <span>
                    {selectedLanguage==='fr' ? formateDate(matchDetails.event_date, 'DD MMMM,YYYY - HH:mm'):formateDate(matchDetails.event_date, 'MMM Do,YY - h:mm a')}
                </span>
            </div>
            <div className="prediction-message-container">
            {isHomeTeamPredicted || isAwayTeamPredicted ?
                isHomeTeamPredicted && isAwayTeamPredicted ?
                <span className="prediction-message">
                    <FormattedMessage id='you-predtcted' defaultMessage='You predicted'/>&nbsp;
                    <FormattedMessage id='a-draw' defaultMessage='a draw'/>
                </span>:
                <span className="prediction-message">
                    <FormattedMessage id='you-predtcted' defaultMessage='You predicted'/>&nbsp;
                    {`${isHomeTeamPredicted ?teamShortHandName(matchDetails.homeTeam.team_name):teamShortHandName(matchDetails.awayTeam.team_name)}`}&nbsp;
                    <FormattedMessage id='win' defaultMessage='win'/>
                </span>:
                <span className="not-prediction-message">
                <FormattedMessage id='not-predicted-yet' defaultMessage='Not predicted yet'/>
               </span>
            }
            </div>
            <div className="match-details">
                <div className={isHomeTeamPredicted ? "predicted" : "non-predicted"}
                 onClick={() =>isEditable &&  handlePrediction(matchDetails,matchDetails.homeTeam.team_id,isHomeTeamPredicted,isAwayTeamPredicted)}
                 >
                    <div className="background">
                        {
                            isHomeTeamPredicted ? <img src={require('./../../assets/images/icons/star-full-1.svg')} className="star-icon" alt="logo" /> :
                                <img src={require('./../../assets/images/icons/star-full-2.svg')} className="star-icon" alt="logo" />
                        }    
                    </div>
                </div>
                <Link to={appendUid('/teamDetails/calendar/'+matchDetails.homeTeam.team_id,getUid())}><img src={matchDetails.homeTeam.logo} className="teamFlag" alt="logo" /></Link>
                <span className="team-name">{matchDetails.homeTeam.team_name.substring(0, 3).toUpperCase()}</span>
                <span className="divider"></span>
                <span className="team-name">{matchDetails.awayTeam.team_name.substring(0, 3).toUpperCase()}</span>
                <Link to={appendUid('/teamDetails/calendar/'+matchDetails.awayTeam.team_id,getUid())}><img src={matchDetails.awayTeam.logo} className="teamFlag" alt="logo" /></Link>
                <div className={isAwayTeamPredicted ? "predicted" : "non-predicted"}
                 onClick={() =>isEditable && handlePrediction(matchDetails,matchDetails.awayTeam.team_id,isHomeTeamPredicted,isAwayTeamPredicted)}
                 >
                    <div className="background">
                        {
                            isAwayTeamPredicted ? <img src={require('./../../assets/images/icons/star-full-1.svg')} className="star-icon" alt="logo" /> :
                                <img src={require('./../../assets/images/icons/star-full-2.svg')} className="star-icon" alt="logo" />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
UpcomingMatchCard.defaultProps={
    isEditable:true
}
UpcomingMatchCard.propTypes = {
    matchDetails: PropTypes.array,
    handlePrediction: PropTypes.func,
    selectedLanguage:PropTypes.string
}

export default UpcomingMatchCard;