import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { config } from '../../constants/domainConfig';
import logo from './../../assets/images/logo.png';
import livescoreActive from './../../assets/images/icons/lightning-1.svg';
import livescore from './../../assets/images/icons/lightning-2.svg';
import livescore_white from './../../assets/images/icons/lightning_white.svg';
import calendarActive from './../../assets/images/icons/clock-2.svg';
import calendar from './../../assets/images/icons/clock-1.svg';
import calendar_white from './../../assets/images/icons/clock_white.svg';
import leaderboardActive from './../../assets/images/icons/trophy-line-1.svg';
import leaderboard from './../../assets/images/icons/trophy-line.svg';
import leaderboard_white from './../../assets/images/icons/trophy_white.svg';
import profileActive from './../../assets/images/icons/profile-2.svg';
import profile from './../../assets/images/icons/profile-1.svg';
import profile_white from './../../assets/images/icons/profile_white.svg';
import videoActive from './../../assets/images/icons/play-2.svg';
import videoIcon from './../../assets/images/icons/play-1.svg';
import videoWhite from './../../assets/images/icons/play-white.svg';
import videoDownload from './../../assets/images/icons/download.png';
import videoDownloadMobile from './../../assets/images/icons/download-white-bg1.png';
import league from './../../assets/images/icons/league.svg';
import league_active from './../../assets/images/icons/league-active.svg';
import league_white from './../../assets/images/icons/league-white.svg';
import { FormattedMessage } from 'react-intl';

import { appendUid } from './../../utils/helper'
import { CustomDropdown } from './../../components/form-elements'
import { selectMenuItem, selectLanguage } from './../../redux/actions/header/action';
import { getGroupRanking} from './../../redux/actions/livescore/action';
import { getMatchDetails,OpenModal } from './../../redux/actions/calendar/action';
import { changeLeague } from './../../redux/actions/registration/action';
import './header.scss';


class Header extends Component {
    constructor(props) {
        super(props)
        this.state={
            isDropDownOpen:false,
            activeMenu:"livescore"
        }
    }

    componentWillMount() {
        // this.handleInitialActiveMenu();
    }
    // handleInitialActiveMenu=()=>{
    //     const {selectMenuItem}=this.props;
    //     let url=window.location.href;

    //     if(url.indexOf("calendar")>=0){
    //         selectMenuItem("calendar");
    //     }else if(url.indexOf("leaderboard")>=0){
    //         selectMenuItem("leaderboard");
    //     }else if(url.indexOf("profile")>=0){
    //         selectMenuItem("profile");
    //     }
    // }
    handleActiveMenu = (menuItem) => {
        this.props.selectMenuItem(menuItem)
    }


    // handleChange(event) {
    //     this.props.changeLang(event.target.value);
    //     this.props.selectLanguage(event.target.value)
    //     this.setState({})
    // };
    handleActiveMenu = (activeMenu) => {
        this.props.OpenModal()
        this.setState({
            activeMenu:activeMenu
        })
    }
    selectItem=(selectedLeague)=>{
       
        this.props.changeLeague(selectedLeague);
        this.props.getGroupRanking(selectedLeague.id);
        this.props.getMatchDetails(selectedLeague.id);
    }
    toggleDropDown=(isOpen)=>{
        this.setState({
            isDropDownOpen:isOpen
        })
    }
    render() {
        const { RegistrationReducer } = this.props;
        const { userDetails,dropDownLeagueslist,selectedLeage } = RegistrationReducer;
        //let activeMenu;
        //activeMenu = this.handleActiveMenu();
        const {activeMenu} =this.state;
        let loggedIn = sessionStorage.getItem("loggedIn"), isVisible = false;
        if (loggedIn) {
            isVisible = JSON.parse(loggedIn).loggedIn;
        }
        return (
            <div className="header-container">
            <div className={this.state.isDropDownOpen ? "overlay" :"overlay-none"}></div>
                <div className="header-menu-container">
                    <Link to={appendUid('/', userDetails.uid)} className="logo-conrainer">
                        <img src={logo} className="logo-img" alt="" />
                    </Link>
                    {/* {isVisible && 
                <div>
                    <img className="menu-image only-for-mobile" src={require('./../../assets/images/icons/alarm-2.svg')} alt=""/> 
                </div>} */}
                    <div className={`header-menu ${config.subDomain === 'gh' || config.subDomain === 'uk' ? 'header-menu-width':'' }`}>
                        <Link to={appendUid('/', userDetails.uid)} className={activeMenu === "livescore" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('livescore')}>
                            <img className="menu-image" src={activeMenu === "livescore" ? livescoreActive : livescore} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='live_scores' defaultMessage='Live Score' />
                            </span>
                        </Link>
                        <Link to={appendUid('/calendar', userDetails.uid)} className={activeMenu === "calendar" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('calendar')}>
                            <img className="menu-image" src={activeMenu === "calendar" ? calendarActive : calendar} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='calendar' defaultMessage='Calendar' />
                            </span>
                        </Link>
                        <Link to={appendUid('/leaderboard', userDetails.uid)} className={activeMenu === "leaderboard" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('leaderboard')}>
                            <img className="menu-image" src={activeMenu === "leaderboard" ? leaderboardActive : leaderboard} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='leaderboard' defaultMessage='Leaderboard' />
                            </span>
                        </Link>
                        {/* <Link to={appendUid('/leagues', userDetails.uid)} className={activeMenu === "leagues" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('leagues')}>
                            <img className="menu-image" src={activeMenu === "leagues" ? league_active : league} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='Leagues' defaultMessage='Leagues' />
                            </span>
                        </Link> */}
                        <Link to={appendUid('/profile', userDetails.uid)} className={activeMenu === "profile" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('profile')}>
                            <img className="menu-image" src={activeMenu === "profile" ? profileActive : profile} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='profile' defaultMessage='Profile' />
                            </span>
                        </Link>
                        {config.subDomain === 'gh' && <Link to={appendUid('/videos', userDetails.uid)} className={activeMenu === "videos" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('videos')}>
                            <img className="menu-image" src={activeMenu === "videos" ? videoActive : videoIcon} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='videos' defaultMessage='Videos' />
                            </span>
                        </Link>}
                        {config.subDomain === 'uk' && <Link to={appendUid('/videos', userDetails.uid)} className={activeMenu === "videos" ? "active-menu-item" : "menu-item"} onClick={() => this.handleActiveMenu('videos')}>
                            <img className="menu-image" src={activeMenu === "videos" ? videoActive : videoIcon} alt="" />
                            <span className="menu-text">
                                <FormattedMessage id='videos' defaultMessage='Videos' />
                            </span>
                        </Link>}
                        {config.subDomain === 'uk' ?
                            <a href = {require('./../../assets/apk/striker.apk')} download className='menu-item'>
                            <img className="menu-image" src = {videoDownload} alt="" />
                                <span className="menu-text">
                                    <FormattedMessage id='download' defaultMessage='Download App' />
                                </span>
                            </a>
                           : null
                        }
                        {/* <div className="menu-notification">
                        <img className="menu-image" src={require('./../../assets/images/icons/alarm-1.svg')} alt=""/>
                    </div> */}
                        {/* <div>
                        <select className="select-language only-for-pc" onChange={this.handleChange.bind(this)}>
                            <option value="en">EN</option>
                            <option value="ar">AR</option>
                            <option value="fr">FR</option>
                        </select>
                    </div> */}
                    </div>
                </div>
                {userDetails.isRegCompleted ? <CustomDropdown
                    options={dropDownLeagueslist}
                    customStyle={'dd-league-style'}
                    selectItem={this.selectItem}
                    selectedItem={selectedLeage}
                    noBorder={window.screen.width <= 768}
                    toggleDropDown={this.toggleDropDown}
                    placeholder={
                        <FormattedMessage id='select_your_league' defaultMessage="Select your league"/>
                    } 
                />:null
                }
                {userDetails.isRegCompleted ?
                    <div className="bottom-menu">
                        <Link to={appendUid('/', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('livescore')}>
                            <img className="menu-image" src={activeMenu === "livescore" ? livescoreActive : livescore_white} alt="" />
                        </Link>
                        <Link to={appendUid('/calendar', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('calendar')}>
                            <img className="menu-image" src={activeMenu === "calendar" ? calendarActive : calendar_white} alt="" />
                        </Link>
                        <Link to={appendUid('/leaderboard', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('leaderboard')}>
                            <img className="menu-image" src={activeMenu === "leaderboard" ? leaderboardActive : leaderboard_white} alt="" />
                        </Link>
                        {/* <Link to={appendUid('/leagues', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('leagues')}>
                            <img className="menu-image" src={activeMenu === "leagues" ? league_active : league_white} alt="" />
                        </Link> */}
                        <Link to={appendUid('/profile', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('profile')}>
                            <img className="menu-image" src={activeMenu === "profile" ? profileActive : profile_white} alt="" />
                        </Link>
                        {config.subDomain === 'gh' && <Link to={appendUid('/videos', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('videos')}>
                            <img className="menu-image" src={activeMenu === "videos" ? videoActive : videoWhite} alt="" />
                        </Link>}
                        {config.subDomain === 'uk' && <Link to={appendUid('/videos', userDetails.uid)} className="active-menu-item" onClick={() => this.handleActiveMenu('videos')}>
                            <img className="menu-image" src={activeMenu === "videos" ? videoActive : videoWhite} alt="" />
                </Link>}
                {config.subDomain === "uk" && (
                  <a
                    href={require("./../../assets/apk/striker.apk")}
                    download
                    className="active-menu-item"
                  >
                    <img
                      className="menu-image"
                      style={{ width: "26px", height: "26px" }}
                      src={videoDownloadMobile}
                      alt=""
                    />
                  </a>
                )}
                    </div>:null
                }
            </div>
        )
    }

}

Header.prototypes = {
    activeMenu: PropTypes.string,
    selectMenuItem: PropTypes.func
}

const mapStateToProps = state => {
    return {
        activeMenu: state.HeaderReducer.activeMenu,
        RegistrationReducer: state.RegistrationReducer
    }
}
const mapDispatchToProps = dispatch => {
    return {
        selectMenuItem: bindActionCreators(selectMenuItem, dispatch),
        selectLanguage: bindActionCreators(selectLanguage, dispatch),
        changeLeague: bindActionCreators(changeLeague, dispatch),
        getGroupRanking: bindActionCreators(getGroupRanking, dispatch),
        getMatchDetails: bindActionCreators(getMatchDetails, dispatch),
        OpenModal: bindActionCreators(OpenModal,dispatch)

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);