//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,SimpleTextbox,SimpleDropDown,ErrorMessage} from './../form-elements';
import { FormattedMessage } from 'react-intl';
import {languages} from './../../constants/common'
import './profile.scss';
import Loader from './../core/loader/loader';

const EditProfile = ({handleRouting,handleChange,fields,errors,countryList,isSaving}) => (
        <div className="edit-profile-form">
            <div className="form-field">
                <span className="edit-profile-title"><FormattedMessage id='edit_profile' defaultMessage='Edit Profile'/></span>
            </div>
            <div className="form-field">
                <SimpleTextbox placeholder={
                    <FormattedMessage id='nickname' defaultMessage='Nickname'/>
                } 
                    type="text"
                    name="name"
                    value={fields['name'] || ''}
                    onChange={(e)=>handleChange('name',e)}
                    IconComponent={
                            ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                        }
                />
                <ErrorMessage errorMessage={errors['name']}/>
            </div>
            <div className="form-field">
                <SimpleDropDown 
                    type="text"
                    placeholder="Select your language"
                    options={languages}
                    name="language"
                    value={fields['language'] || ''}
                    onChange={(e)=>handleChange('language',e)}
                    IconComponent={
                            ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                        }
                />
                <ErrorMessage errorMessage={errors['language']}/>
            </div>
            
            <div className="form-field">
                <SimpleDropDown
                    type="text"
                    name="team"
                    value={fields['team'] || ''}
                    onChange={(e)=>handleChange('team',e)}
                    placeholder="Select your team"
                    options={countryList}
                    IconComponent={
                            ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                        }
                />
                <ErrorMessage errorMessage={errors['team']}/>
            </div>
            <Simplebutton onClick={handleRouting} isDisabled={isSaving}  text={isSaving ? null :
                <FormattedMessage id='save_button' defaultMessage='Save'/>
            } isPrimary={false} customStyle={'save-button'}>
            {isSaving ? <Loader customStyle={'loader-size'}/>:   
                <img className="save-button-icon" src={require('./../../assets/images/icons/right-arrow.svg')} alt="login-icon"/>
            }
            </Simplebutton>
        </div>
);

EditProfile.defaultProps={
}

EditProfile.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
}

export default EditProfile;