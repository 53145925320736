export const GET_MATCH_DETAILS_FETCH = 'GET_MATCH_DETAILS_FETCH';
export const GET_MATCH_DETAILS_SUCCESS = 'GET_MATCH_DETAILS_SUCCESS';
export const GET_MATCH_DETAILS_FAIL = 'GET_MATCH_DETAILS_FAIL';

export const ADD_PREDICTION = 'ADD_PREDICTION';

export const ADD_PREDICTION_POST = 'ADD_PREDICTION_POST';
export const  ADD_PREDICTION_SUCCESS = 'ADD_PREDICTION_SUCCESS';
export const ADD_PREDICTION_FAIL = 'ADD_PREDICTION_FAIL';

export const GET_PREDICTION_FETCH = 'GET_PREDICTION_FETCH';
export const GET_PREDICTION_SUCCESS = 'GET_PREDICTION_SUCCESS';
export const GET_PREDICTION_FAIL = 'GET_PREDICTION_FAIL';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

