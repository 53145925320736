import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TeamDetailsWrapper from './teamDetailsWrapper';
import rightArrow from './../../assets/images/icons/right-arrow-2.svg';
import {teamShortHandName} from './../../utils/dataParser'

const PlayerTransfers = ({teamTransfers}) => {
    return (
         <div className="players-transfer-container">
            <TeamDetailsWrapper heading={"Transfers"}>
            
                <div className="players-transfers">
                    { teamTransfers.length >0?teamTransfers.map((rowData,index)=>{
                        return( <div className="players-transfer-item">
                            <div className="player-details">
                                <span className="dob">{rowData.transfer_date}</span>
                                <span className="">{rowData.player_name}</span>
                            </div>
                            <div className="player-transfer-details">
                                <span className="">{teamShortHandName(rowData.team_out.team_name)}</span>
                                <img src={rightArrow} className="right-arrow" alt="right-arrow"/>
                                <span className="">{teamShortHandName(rowData.team_in.team_name)}</span>
                            </div>
                        </div>)
                    }):
                    <div className="empty-data">
                        <p>Data not available</p>
                    </div>
                }
                </div>
            </TeamDetailsWrapper>
        </div>
    )
}


PlayerTransfers.defaultProps = {

}

PlayerTransfers.propTypes = {
    teamTransfers:PropTypes.array
}

export default PlayerTransfers;