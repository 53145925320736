//without text input
import React from 'react';
import PropTypes from 'prop-types';
import { Simplebutton } from './../form-elements'
import { FormattedMessage } from 'react-intl';
import './login.scss';

const Welcome = ({handleRouting}) => {
    return(
    <div className="login-actions ">
        <div className="login-welcome-page">
            <img src={require('../../assets/images/logo.png')} alt="logo" className="welcome-logo"/>
            <span className="login-subtitle">
            <FormattedMessage id='ultimate_online_football' defaultMessage='Ultimate online football'/>
            </span>
            <div className="login-button">
                <Simplebutton onClick={()=>handleRouting('/login/mobile')} text={
                <FormattedMessage id='log_in' defaultMessage='Log in'/>
            } imagePath='./../../assets/images/icons/logout-white.svg'>
                    <img className="login-icon" src={require('./../../assets/images/icons/logout-white.svg')} alt="logout-icon"/>
                </Simplebutton>
            </div>
        </div>
    </div>
)};

Welcome.defaultProps = {
}

Welcome.propTypes = {

}

export default Welcome;