import { combineReducers, createStore, applyMiddleware } from 'redux';
import RegistrationReducer from './reducers/registrationReducer';
import LivescoreReducer from './reducers/livescoreReducer';
import HeaderReducer from './reducers/headerReducer';
import CalendarReducer from './reducers/calendarReducer';
import LeaderBoardReducer from './reducers/leaderBoardReducer';
import createSagaMiddleware from 'redux-saga'
import MainSaga from './redux-saga/mainSaga';


const sagaMiddleware = createSagaMiddleware()

const appReducer = combineReducers({
    RegistrationReducer,
    LivescoreReducer,
    HeaderReducer,
    CalendarReducer,
    LeaderBoardReducer
});

let store = createStore(appReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(MainSaga);

export default store;