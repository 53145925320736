import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './livescore.scss';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Simplebutton } from './../form-elements';
import {teamShortHandName} from './../../utils/dataParser';
import {timerData} from './../../constants/common'


const LiveScoreCard = ({livescoreData,currentTimerTime}) => {
    return (
            <div class="live-score-card-container">
                <div class="top-bar">
                    <div class="title">
                    <FormattedMessage id='live_scores' defaultMessage='Live scores'/>
                    </div>
                    <div class="time">
                    { livescoreData.statusShort==='NS'? '00:00':timerData.currentTimeTimer}
                    </div>
                </div>
                <div class="box">
                    <div class="scores">
                        <div class="flag">
                        <img className="homeTeamFlag" src={livescoreData.homeTeam.logo}/>
                        {teamShortHandName(livescoreData.homeTeam.team_name)}
                        </div>
                        <div class="score win">
                            {livescoreData.statusShort==='NS' ? "0" : livescoreData.goalsHomeTeam}
                        </div>
                        <div class="divider"></div>
                        <div class="score win">
                            {livescoreData.statusShort==='NS' ? "0" : livescoreData.goalsAwayTeam}
                        </div>
                        <div class="flag">
                            {teamShortHandName(livescoreData.awayTeam.team_name)}
                            <img className="awayTeamFlag" src={livescoreData.awayTeam.logo}/>
                        </div>
                    </div>
                    {/* <div class="live goal">
                        <p>{""}</p>
                    </div> */}
                </div>
            </div>
    )
}

export default LiveScoreCard;