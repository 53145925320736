//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,SimpleTextbox,ErrorMessage} from './../form-elements';
import ComponentWrapper from './componentWrapper'
import { FormattedMessage } from 'react-intl';
import { config } from '../../constants/domainConfig'

import Loader from './../core/loader/loader';
import './login.scss';
import {getFlag, getPlaceHolder} from '../../utils/LoginUtil';

const flagUrl = getFlag(config.country);

const MobileNumber = ({handleRouting,handleChange,fields,errors,isSaving}) => (
    <ComponentWrapper heading={
        <FormattedMessage id='are_you_a_striker' defaultMessage='Are you a striker?'/>
    } subheading={
        <FormattedMessage id='enter_mobile_number' defaultMessage='Enter you mobile number right here'/>
    }>
        <div className="login-form">
            <div className="flag_img">
                {flagUrl ? <img src={flagUrl} className="flag_url" alt={config.country} /> : null}
            </div>
            <SimpleTextbox placeholder={
                <FormattedMessage id='phone_number' defaultMessage='Phone number'/>
            }
                type="number"
                inputPlaceHolder={getPlaceHolder(config.country)}
                value={ fields['mobile']}
                onChange={handleChange}
                name="mobile"
                IconComponent={
                        ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                    }
            />
            {errors!=="" ? <ErrorMessage errorMessage={errors['mobile']}/>:null}
            <Simplebutton onClick={()=>handleRouting('/login/language')} isDisabled={isSaving}
            text={isSaving ? null :<FormattedMessage id='log_in' defaultMessage='Log in'/>} isPrimary={false} customStyle={'login-inputbox'}>
                {isSaving ? <Loader customStyle={'loader-size'}/>:   
                <img className="login-icon" src={require('./../../assets/images/icons/logout-color.svg')} alt="login-icon"/>}
            </Simplebutton>
        </div>
    </ComponentWrapper>
);

MobileNumber.defaultProps={
}

MobileNumber.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
}

export default MobileNumber;