import React, { Component } from 'react';
// import './login.scss';
import PropTypes from 'prop-types';


const RankingTableWithoutGroup=({poolRankingList,showTeamDetails})=>{
    return(
        poolRankingList.map((rowData, index) => {
            return (
                <table className="standings">
                    <tr className="standing-head">    
                        <th className="wt-group-name"></th>
                        <div className="group-content group-head wt-group-content">
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>S</th>
                        </div>
                    </tr>
                        <tr className="standing-heads-second-row">
                            <th className="wt-group-name"></th>
                            <div className="group-content group-head wt-group-content">
                                <th>W</th>
                                <th>D</th>
                                <th>L</th>
                                <th>S</th>
                            </div>
                        </tr>
                    {rowData.map((groupData, index) => {
                        return (
                            <tr className="team-content-withoutgroup" onClick={()=>showTeamDetails(groupData)}>
                                {/* <td>
                                </td> */}
                                <td className="team-name">
                                    <span className="team-srno">{groupData.rank}.</span>
                                    {groupData.teamName === "South Sudan" ?
                                        <img src={require('./../../assets/images/south_sadan.png')}  className="team-flag"/>:
                                        <img src={groupData.logo}  className="team-flag"/>
                                                }
                                     <span style={{verticalAlign: 'super'}}>{groupData.teamName}</span>
                                </td>
                                <div className="group-content wt-group-content">
                                    <td>{groupData.all.win}</td>
                                    <td>{groupData.all.draw}</td>
                                    <td>{groupData.all.lose}</td>
                                    <td>{groupData.points}</td>
                                </div>
                            </tr>
                        )
                    })}
                </table>
            )
        
        
        })
    )
    }

RankingTableWithoutGroup.prototype={
    poolRankingList:PropTypes.array,
}
export default RankingTableWithoutGroup;

