import React ,{Component} from 'react';
import PropTypes from 'prop-types';

import './formElements.scss';

const ErrorMessage=({errorMessage})=> {

        return(
            <div>
                <span className="error-message">{errorMessage}</span>
            </div >
        )
    }

ErrorMessage.propTypes = {
    errorMessage: PropTypes.string.isRequired,

}

export default ErrorMessage;