import * as action_type from './../actions/livescore/action_types';
const initialState = {
    poolRankingList: [],
    liveScore: [],
    currentTimerTime: '00:00',
    minutes: 0,
    seconds: 0,
    teamStats: [],
    teamTransfers: [],
    teamPlayers: [],
    playerDetails:[],
    teamDetails:[]
}
const LivescoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.POOL_RANKING_SUCCESS:
            return Object.assign({}, state, {
                poolRankingList: action.data.api.standings
            });
        case action_type.GET_LIVE_SCORE_SUCCESS:
            // let LiveScoreData=action.data.api.fixtures.filter(rowData=>(rowData.league_id===action.league_id));
            // console.log("LiveScoreData",LiveScoreData)
            return Object.assign({}, state, {
                liveScore: action.data
            });
        case action_type.SET_TIMER_TIME:
            return Object.assign({}, state, {
                currentTimerTime: action.data.currentTimerTime,
                minutes: action.data.minutes,
                seconds: action.data.seconds,
            });
        case action_type.GET_TEAM_STATS_SUCCESS:
            return Object.assign({}, state, {
                teamStats: action.data.api.statistics
            });
        case action_type.GET_TEAM_TRANSFERS_SUCCESS:
            return Object.assign({}, state, {
                teamTransfers: action.data.api.transfers
            });
        case action_type.GET_TEAM_PLAYERS_SUCCESS:
            return Object.assign({}, state, {
                teamPlayers: action.data.api.players
            });
        case action_type.GET_PLAYER_BY_ID_SUCCESS:
            return Object.assign({}, state, {
                playerDetails: action.data.api.players
            });
        case action_type.GET_TEAM_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                teamDetails: action.data.api.teams[0]
            });
        default:
            return state;
    }
}

export default LivescoreReducer;