import React, { Component } from 'react';
// import './login.scss';
import PropTypes from 'prop-types';
import RankingTableWithGroup from './rankingTableWithGroup'
import RankingTableWithoutGroup from './rankingTableWithoutGroup'


const RankingTableWrapper=({LivescoreReducer,showTeamDetails})=>{
    const {poolRankingList}=LivescoreReducer;
    let isGroupRankingTable=poolRankingList && poolRankingList.length > 1;
    const commonProps={showTeamDetails,poolRankingList}
    return(
        LivescoreReducer.poolRankingList && LivescoreReducer.poolRankingList.length > 0 ?
            isGroupRankingTable ? 
            <RankingTableWithGroup {...commonProps}/>:
            <RankingTableWithoutGroup {...commonProps}/>
           : null
    )
}
RankingTableWrapper.prototype={
    LivescoreReducer:PropTypes.object,
    showTeamDetails:PropTypes.func
}
export default RankingTableWrapper;