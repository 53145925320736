import * as action from './action_types';

export function updatePredictionsPoints(data) {
    return {
        type: action.UPDATE_PREDICTION_POINTS_POST,
        data:data
    }
};

export function getLeadersPoints(){
    return{
        type:action.GET_LEADERS_POINT_FETCH
    }
}