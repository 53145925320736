import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {Link} from 'react-router-dom'

import './calendar.scss';
import { formateDate, findIndex, pointsCalculator,appendUid,getUid } from './../../utils/helper';
import { teamShortHandName } from './../../utils/dataParser';


const PreviousMatchCard = ({ matchDetails, predicatedData, selectedLanguage }) => {
    let isHomeTeamPredicted = findIndex(predicatedData, { predicated_team_one_id: matchDetails.homeTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
    let isAwayTeamPredicted = findIndex(predicatedData, { predicated_team_two_id: matchDetails.awayTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
    let hwin = matchDetails.goalsHomeTeam > matchDetails.goalsAwayTeam ? true : false;
    let awin = matchDetails.goalsHomeTeam < matchDetails.goalsAwayTeam ? true : false;
    let matchResultData = pointsCalculator(isHomeTeamPredicted, isAwayTeamPredicted, matchDetails)
    return (
        <div className="match-card previous-match-card-padding" key={"previpus-matches+" + matchDetails.fixture_id}>
            <div className="match-date">
                <span>{selectedLanguage === 'fr' ? formateDate(matchDetails.event_date, 'DD MMMM,YYYY - HH:mm') : formateDate(matchDetails.event_date, 'MMM Do,YY - h:mm a')}</span>
            </div>
            <div className="match-details">
                <Link to={appendUid('/teamDetails/calendar/'+matchDetails.homeTeam.team_id,getUid())}><img src={matchDetails.homeTeam.logo} className="teamFlag" alt="logo" /></Link>
                <span className="team-name">{matchDetails.homeTeam.team_name.substring(0, 3).toUpperCase()}</span>
                <span className={`previous-match-goals ${hwin ? 'win' : 'lose'}`}>{matchDetails.goalsHomeTeam}</span>
                <span className="divider"></span>
                <span className={`previous-match-goals ${awin ? 'win' : 'lose'}`}>{matchDetails.goalsAwayTeam}</span>
                <span className="team-name">{matchDetails.awayTeam.team_name.substring(0, 3).toUpperCase()}</span>
                <Link to={appendUid('/teamDetails/calendar/'+matchDetails.awayTeam.team_id,getUid())}><img src={matchDetails.awayTeam.logo} className="teamFlag" alt="logo" /></Link>
                <div></div>
            </div>
            <div className="predicted-banner-container">
                {isHomeTeamPredicted || isAwayTeamPredicted ?
                    <div className={`predicted-banner-content ${matchResultData.matchResult === 'W' ? 'win-color' : 'lose-color'}`}>
                            <div>
                                <span className="title">
                                    <FormattedMessage id='you-predtcted' defaultMessage='You predicted' />
                                </span><br />
                                {isHomeTeamPredicted && isAwayTeamPredicted ?
                                    <span><FormattedMessage id='a-draw' defaultMessage='a draw' /></span>:
                                    <span>{`${isHomeTeamPredicted ? teamShortHandName(matchDetails.homeTeam.team_name) : teamShortHandName(matchDetails.awayTeam.team_name)}`} <FormattedMessage id='win' defaultMessage='win' /></span>
                                }
                            </div>
                        <div>
                            <span className="prediction-points">+{matchResultData.points}</span>
                            <img src={require('./../../assets/images/icons/star-line.svg')} />
                        </div>
                    </div>
                    :
                    null
                    // <div className="no-predicted-banner">
                    //     <div>
                    //         <span className="title">No prediction yet</span><br />
                    //     </div>
                    //  </div>
                }
            </div>
        </div>
    )
}
PreviousMatchCard.propTypes = {
    matchDetails: PropTypes.array,
    selectedLanguage: PropTypes.string
}

export default PreviousMatchCard;