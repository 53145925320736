import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";

import MobileNumber from './mobileNumber';
import Welcome from './welcome';
import NickName from './nickName';
import Team from './team';
import Language from './language';
import Leagues from './leagues';
import './login.scss';
import history from './../../utils/history';
import loginWrapper from './loginHOC';
import API from './../../lib/api';
import Header from './../core/header';
import {appendUid} from './../../utils/helper'
import { config } from '../../constants/domainConfig'
import { sendData } from '../../analytics/sendData';
import { isValid, parse } from '../../utils/LoginUtil';

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            fields: {
            },
           errors: {},
           isSaving:false,
           interactionDataCollected:false
        }
    }
    componentWillMount(){
        let token=sessionStorage.getItem("token"),isLoggedIn;
        if(token){
            isLoggedIn=JSON.parse(token).token;
            if(isLoggedIn){
                this.props.getUserDetails();
            }
        }
    }
    componentDidUpdate() {
        if (window.location.pathname === "/login/mobile" &&  this.state.interactionDataCollected==false) {
            sendData('', '', 'football match' , 'viewed');
            this.setState({
                interactionDataCollected: true
            })
        }
    }

    componentDidUpdate(){
        if (window.location.pathname == '/login/mobile' && this.state.interactionDataCollected == false){

            console.log('/login/mobile found in update');
            // sendData()
            this.setState({
                interactionDataCollected:true
            })
        } 
    }
    formatMobileNumber(number){
        console.log((config.countryCode).concat(number.slice(-1 * (config.msisdnLength))));
        return ((config.countryCode).concat(number.slice(-1 * (config.msisdnLength))));
    }
    handleRouting = (path) => {
        const { RegistrationReducer } = this.props;
        const {userDetails} =RegistrationReducer;
        let phoneNumber=parse(userDetails.mobile,config.subDomain);

        const {fields}=this.state;
        if (path === "/login/mobile") {
            this.props.history.push(path)
        } 
        else {
            if (this.handleValidation()) {
                if(path==="/login/language"){
                    this.setState({
                        isSaving:true
                    })
                    API.checkUserAuth(phoneNumber,'mobile').then(data=>{
                        this.setState({
                            isSaving:false
                        },()=>{
                            if(data.success){
                                sessionStorage.setItem('rockmanId', data.data.payload.rockmanId);
                                sessionStorage.setItem("token",JSON.stringify({token:data.data.token,success:data.success}));
                                this.props.history.push(appendUid(path,data.data.payload.token)) 
                            }else{
                                toast.error('This mobile number is not registered.', {
                                  position: toast.POSITION.TOP_CENTER
                                });
                                this.setState({errors:{}})
                            }
                        })
                    });
                }else{
                    this.setState({
                        isSaving:true
                    })
                    let dataToBeSend = this.parseDataForBackend();
                    API.editProfile(dataToBeSend).then(data => {
                        this.setState({
                            isSaving:false
                        },()=>{
                            if (data.success) {
                                if(path==='/'){
                                    this.props.getUserDetails();
                                    sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
                                }
                                this.props.history.push(appendUid(path,userDetails.uid))
                            }
                        })
                      
                    })
                }
            }
        }

    }
    parseDataForBackend=()=>{
        const { match,RegistrationReducer } = this.props;
        const fields=RegistrationReducer.userDetails;
        let parsedData={...fields};
        let activeTab = match.params.active;
        
            parsedData.language=fields['language'].value
            parsedData.team= "";
            parsedData.teamFlag="";
        
        return parsedData
    }
    UNSAFE_componentWillReceiveProps(nextProps){
    //     const {RegistrationReducer,history} =nextProps;
    //     const accessData=RegistrationReducer.accessData
    //  const nextData=nextProps.RegistrationReducer;
    //  const prevData=this.props.RegistrationReducer;
    //  if(nextData.accessData.success!==prevData.accessData.success && nextData.accessData.success){
    //      if(accessData.userDetails.isRegCompleted){
    //         sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
    //          history.push('/')
    //      }else{
    //          history.push('/login/nickname')
    //      }
    //  }
   }

    handleValidation = () => {
        const { match,RegistrationReducer } = this.props;
        let fields=RegistrationReducer.userDetails;
        const { errors } = this.state;
        let formIsValid = true;
        let activeTab = match.params.active;
        if (activeTab === 'mobile') {
            if(isValid(fields["mobile"],config.subDomain)){
                formIsValid=true;
            }
            else {
                 if (!fields["mobile"]) {
                    formIsValid = false;
                    errors["mobile"] = "Mobile no cannot be empty.";
                }
                else{
                    formIsValid=false;
                    errors["mobile"] = "Invalid phone number."
                }
            }
        }
        if (activeTab === 'nickname') {
            if (!fields["name"] && fields["name"]===null) {
                formIsValid = false;
                errors["name"] = "Name cannot be empty.";
            }
            console.log("fields[name]",fields["name"])
            if (typeof fields["name"] !== "undefined" && fields["name"]!==null) {
                if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
                    formIsValid = false;
                    errors["name"] = "Only letters allowed";
                }
            }

        }
        if (activeTab === 'language') {
            if (fields["language"].value==='' || fields["language"].value===null) {
                formIsValid = false;
                errors["language"] = "Language cannot be empty.";
            }
        }
        if (activeTab === 'leagues') {
            if (fields["league_id"]==='' || fields["league_id"]===null) {
                formIsValid = false;
                errors["league"] = "League cannot be empty.";
            }
        }
        if (activeTab === 'team') {
            if (fields["team"].value==='' || fields["team"].value===null) {
                formIsValid = false;
                errors["team"] = "Team cannot be empty.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;

    }
    handleChange=(e)=>{     
        const { match,RegistrationReducer,editProfile } = this.props;
        let fields = RegistrationReducer.userDetails;
        let activeTab = match.params.active;
        if(activeTab==="language" || activeTab==="team"){
            fields[activeTab] = e;  
            if(activeTab==="language"){
                this.props.selectLanguage(e.value)
            }
        }else{
            fields[e.target.name] = e.target.value;  
        }
        console.log('activeTab',activeTab,fields)
        editProfile(fields)
        // this.setState({fields})
    }
    selectItem=(selectedLeague)=>{
        const { RegistrationReducer,editProfile,changeLeague } = this.props;
        let fields = RegistrationReducer.userDetails;
        fields['league_id']=selectedLeague.id;
        fields['league_name']=selectedLeague.value;
        fields['league_logo']=selectedLeague.img;
        editProfile(fields);
       changeLeague(selectedLeague);


    }

    renderTab(props){
        const {match,RegistrationReducer} = props;
        const {fields,errors,isSaving} = this.state;
        let activeTab=match.params.active;
        let commonProps={
            handleChange:this.handleChange,
            handleRouting:this.handleRouting,
            fields:RegistrationReducer.userDetails,
            errors:errors,
            isSaving
        }
        switch(activeTab){
            case 'mobile':
                    return <MobileNumber handleRouting={this.handleRouting} {...commonProps}/>;
                break;
            case 'nickname':
                return <NickName handleRouting={this.handleRouting} {...commonProps}/>;
                break;
            case 'language':
                return <Language {...commonProps}/>;
                break;
            case 'leagues':
                return <Leagues options={RegistrationReducer.dropDownLeagueslist} {...commonProps} selectItem={this.selectItem}/>;
                break;
            default:
                return <Welcome handleRouting={this.handleRouting} {...commonProps}/>
        }
    }
    render() {
        const {match,selectedLanguage} =this.props;
        let url=window.location.href
        return (
            <div className={"login-container"}>
                <div className="only-for-mobile">
                    <Header/>
                </div>
                <div className="login-background-image">
                    {url.indexOf("welcome")===-1 ?
                        <img src={require('./../../assets/images/login-page-banner2.png')} alt="banner"/>:
                        <img src={require('./../../assets/images/login-page-banner1.png')} alt="banner"/>
                    }
                </div>
                <div className="login-actions">
                    {this.renderTab(this.props)}
                </div>
            </div>
        )
    }

}

export default loginWrapper(Login);