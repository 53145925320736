import React from "react";
import VideoData from "./videos.json";
import ReactPlayer from "react-player";
import { Redirect } from "react-router-dom";
import "./video-play.scss";
import VideoCard from "./VideoCard";
import { Row, Col } from "react-simple-flex-grid";

class VideoPlayer extends React.Component {
  render() {
    const videoId = this.props.match.params.videoId;
    const videoDetails = VideoData.find((i) => i.id === parseInt(videoId));
    const relatedVideos = VideoData.filter(i => i.id !== parseInt(videoId));
    if (!videoId || !videoDetails) return <Redirect to="/" />;
    return (
      <div className="video-player-page">
        <div className="video-player">
          <ReactPlayer
          key={videoDetails.id}
            url={videoDetails.videoURL}
            controls={true}
            playsinline
            playing
            width="100%"
            height="100%"
          />
        </div>
        <div className="video-title">
            {videoDetails.title}
        </div>
        <div className="related-video-title">
            Related Videos
        </div>
        <div className="video-container">
          <Row
            gutter={10}
            style={{
              marginTop: "1rem",
            }}
          >
            {relatedVideos.map((item) => {
              return (
                <Col key={item.id} xs={6} sm={4} md={4} lg={3} xl={3}>
                  <VideoCard key={item.id} {...item} onClick={
                    () => this.props.history.push("/play/" + item.id)
                  } />
              </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
