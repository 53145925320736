//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,SimpleDropDown,ErrorMessage} from './../form-elements';
import ComponentWrapper from './componentWrapper';
import { FormattedMessage } from 'react-intl';
 import loginWrapper from './loginHOC';
 import {languages} from './../../constants/common';
 import Loader from './../core/loader/loader';

import './login.scss';


const  Language = ({handleRouting,handleChange,fields,errors,isSaving}) =>{ 
    console.log("fields['language']",fields['language'])
    let defaultValue=fields['language'] && fields['language'].value!==null? fields['language']:'';
    return(
    <ComponentWrapper heading={<FormattedMessage id='language' defaultMessage='Language'/>} subheading={<FormattedMessage id='select_your_language' defaultMessage='Select your language'/>}>
        <div className="login-form">
            <SimpleDropDown 
                type="text"
                placeholder={
                    <FormattedMessage id='language_dropdown_placeholder' defaultMessage="Select your language"/>
                } 
                options={languages}
                name="language"
                value={defaultValue}
                onChange={handleChange}
                IconComponent={
                        ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                    }
            />
            <ErrorMessage errorMessage={errors['language']}/>
            <Simplebutton onClick={()=>handleRouting('/login/nickname')} isDisabled={isSaving}  text={isSaving ? null :<FormattedMessage id='continue' defaultMessage='Continue'/>} isPrimary={false} customStyle={'login-inputbox'}>
                 {isSaving ? <Loader customStyle={'loader-size'}/>:   
                    <img className="login-icon" src={require('./../../assets/images/icons/right-arrow.svg')} alt="login-icon"/>
                 }
            </Simplebutton>
        </div>
    </ComponentWrapper>
)};

Language.defaultProps={
}

Language.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
}

export default Language;