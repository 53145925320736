import { getSessionCookie } from './session';
import { config } from '../constants/domainConfig';
import { analyticsUrl } from '../constants/common';

export const sendData = (cName, cCategory, cType, uType) => {
    const sessionId = getSessionCookie();
    const subDomain = config.subDomain;
    const args = {
        url: window.location.href,
        lang: config.lang
    }
    const rockmanId = sessionStorage.getItem('rockmanId') !== 'test_rockman_id' ? sessionStorage.getItem('rockmanId')  : '';
    if(rockmanId && rockmanId.length > 0){
        const data = {
            r: rockmanId,
            portal_session_id: sessionId,
            d: [
                {
                    t: "content_usage",
                    a: {
                        content_name: cName,
                        content_category: cCategory,
                        content_type: cType,
                        usage_type: uType,
                        args: args,
                        product_name: "strikers",
                        subdomain: subDomain
                    }
                }
            ]
        };
        // console.log(data)
        var xhr = new XMLHttpRequest();
        xhr.open('POST', analyticsUrl, true);
        // xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(data));
    }
    return;
}