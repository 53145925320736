import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import history from './../../utils/history'
import './profile.scss'
import { Link } from 'react-router-dom';
import { Simplebutton, CustomDropdown } from './../form-elements';
import { FormattedMessage } from 'react-intl';
import UpcomingMacthesCard from './../calendar/upcomingMacthesCard';
import { selectLanguage } from './../../redux/actions/header/action';
import { editProfile } from './../../redux/actions/registration/action';
import { getPredictions, getMatchDetails, addPrediction } from './../../redux/actions/calendar/action';
import API from './../../lib/api';
import EditProfile from './editProfile';
import { findIndex } from './../../utils/helper';
import { config } from './../../constants/domainConfig'
import { sendData } from '../../analytics/sendData';
import { ghSubscriptionInst } from '../../constants/common';
import Accordion from '../accordion/Accordion';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            errors: {},
            isSaving: false
        }
    }
    componentWillMount() {
        let seletctedLeagueId = this.props.RegistrationReducer.selectedLeage.id;

        this.props.getMatchDetails(seletctedLeagueId);
        this.props.getPredictions();
    }
    componentDidMount() {
        sendData('', '', 'football match', 'viewed');
    }

    handleRouting = () => {
        if (this.handleValidation()) {
            this.setState({
                isSaving: true
            })
            let dataToBeSend = this.parseDataForBackend();
            API.editProfile(dataToBeSend).then(data => {

                if (data.success) {
                    this.props.selectLanguage(data.data.language)
                    this.setState({
                        isEditable: false,
                    })
                    alert("Your profile was successfully updated")
                } else {
                    alert("Something went wrong. Please try again.")
                }
                this.setState({
                    isSaving: false
                })
            })
        }
    }
    parseDataForBackend = () => {
        const { RegistrationReducer } = this.props;
        const fields = RegistrationReducer.userDetails;
        let parsedData = { ...fields };
        parsedData.language = fields['language'].value
        parsedData.team = fields['team'].value;
        parsedData.teamFlag = fields['team'].flag;

        return parsedData
    }
    handleLanguageChange(event) {
        this.props.selectLanguage(event.target.value)
    };

    handleChange = (fieldName, e) => {
        const { RegistrationReducer, editProfile } = this.props;
        let fields = RegistrationReducer.userDetails;
        if (fieldName === "language" || fieldName === "team") {
            fields[fieldName] = e;
        } else {
            fields[e.target.name] = e.target.value;
        }
        editProfile(fields)
        // this.setState({fields})
    }

    handleValidation = () => {
        const { match, RegistrationReducer } = this.props;
        let fields = RegistrationReducer.userDetails;
        const { errors } = this.state;
        let formIsValid = true;
        let activeTab = match.params.active;

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Name cannot be empty.";
        }

        if (typeof fields["name"] !== "undefined") {
            if (!fields["name"].match(/^[a-zA-Z ]+$/)) {
                formIsValid = false;
                errors["name"] = "Only letters allowed";
            }
        }
        if (!fields["language"]) {
            formIsValid = false;
            errors["language"] = "Language cannot be empty.";
        }
        if (!fields["team"]) {
            formIsValid = false;
            errors["team"] = "Team cannot be empty.";
        }
        this.setState({ errors: errors });
        return formIsValid;

    }
    handleEditProfileToggle = () => {
        this.setState({
            isEditable: true
        })
    }
    handleDeleteUser = () => {
        API.deleteUser().then(res => {
            if (res.success) {
                sessionStorage.clear();
                this.props.history.push('/welcome');
            }
        })
    }
    handleUnsubscription = () => {
        const { RegistrationReducer, selectedLanguage } = this.props;
        let uid = RegistrationReducer.userDetails.uid
        sendData('','','','Unsubscribed')
        // if (config.subDomain === 'eg') {
        //     window.open(`http://n.striker-live.com/unsubscribe/eg?lang=ar&token=${uid}`, '_blank')
        // }
        if(sessionStorage.getItem("staging") === "true" && config.subDomain === "za"){
            window.open(`http://ads-za-yola.sam-media.com/unsubscribe/za1?country=ae&lang=en&token=${uid}`)
        }
        if (config.subDomain === 'gh') {
            window.open(`https://checksubscription.com/?c=gh`)
            
        } else {
            window.open(`http://n.striker-live.com/unsubscribe/${config.subDomain}?lang=${selectedLanguage}&token=${uid}`, '_blank')
        }
    }
    handlePrediction = (matchData, predicated_id, isHomeTeamPredicted, isAwayTeamPredicted) => {
        const { addPrediction } = this.props;
        let predictionObj = {
            "matchid": matchData.fixture_id,
            "team_one_name": matchData.homeTeam.team_name,
            "team_one_id": matchData.homeTeam.team_id,
            "team_one_flag": matchData.homeTeam.logo,
            "team_two_name": matchData.awayTeam.team_name,
            "team_two_id": matchData.awayTeam.team_id,
            "team_two_flag": matchData.awayTeam.logo,
            "predicated_team_one_id": isHomeTeamPredicted ? matchData.homeTeam.team_id : predicated_id === matchData.homeTeam.team_id ? predicated_id : null,
            "predicated_team_two_id": isAwayTeamPredicted ? matchData.awayTeam.team_id : predicated_id === matchData.awayTeam.team_id ? predicated_id : null,
            "matchDateTime": matchData.event_date,
            "predicated_id": predicated_id
        }
        let otherData = {
            predicated_id,
            isAwayTeamPredicted,
            isHomeTeamPredicted
        }
        addPrediction(predictionObj, otherData)
    }
    render() {
        const { RegistrationReducer, selectedLanguage, CalendarReducer, matchesList } = this.props;
        const { isEditable, errors, isSaving } = this.state;
        const { userDetails } = RegistrationReducer;

        let defaultLang = selectedLanguage ? selectedLanguage : (userDetails.language && userDetails.language.value)
        let predicatedData = CalendarReducer.predictedData;
        // let parsedMatchesLsit=matchesList.filter((data)=>{
        //     return (formateDate(data.event_date,'DD-MM-YYYY')===formateDate(selectedDate,'DD-MM-YYYY') && data.statusShort === status)
        // })
        let url = window.location.href;
        let firstFourPrediction = predicatedData.length > 0 ? predicatedData.slice(0, 4) : [];
        return (
            <div className="profile-container">
                <div className="profile-container-player">
                    <div className="container-player">
                        <img src={require('./../../assets/images/player-nb.png')} style={{ width: '80%' }} alt='player' />
                    </div>
                </div>
                <div className="profile-container-score">
                    {!isEditable ?
                        <div style={{ width: '100%' }}>
                            <div className={`container-score ${config.subDomain === 'gh' ? 'unset-height':''}`}>
                                <div className="heading-style">
                                    <span>
                                        <FormattedMessage id='welcome_back' defaultMessage='Welcome back !' />
                                    </span>
                                    {config.subDomain === 'ci' ?
                                    <div className="download-apk">
                                        <a href={require('./../../assets/apk/striker.apk')} download><span>
                                            <FormattedMessage id="Download App" defaultMessage="Download App" />    
                                        </span></a>
                                    </div> : null
                                    }
                                    <select className="select-language" value={defaultLang} onChange={this.handleLanguageChange.bind(this)}>
                                        <option value="en">EN</option>
                                        <option value="ar">AR</option>
                                        <option value="fr">FR</option>
                                    </select>
                                </div>

                                <div className="person-score-rank">
                                    <div className="person">
                                        <div className="user-account-info">
                                            {userDetails.team ? <img className="team-flag" src={userDetails.league_logo} alt='flag' /> : null}
                                            <p className="user-name">{userDetails.name}</p>
                                        </div>
                                        {/* <Link to="/calendar"> */}
                                        <Simplebutton customStyle={'edit-button'} onClick={this.handleEditProfileToggle} text={
                                            <FormattedMessage id='edit' defaultMessage='Edit' />
                                        }>
                                            {/* <p className="edit-button-text">Edit</p> */}
                                            <img className="edit-button-icon" src={require('./../../assets/images/icons/pen-white.svg')} alt="login-icon" />
                                        </Simplebutton>
                                        {/* </Link> */}
                                    </div>
                                    <div className="score-rank">
                                        <div className="score-rank-design">
                                            <div className="score-rank-title">
                                                <span>
                                                    <FormattedMessage id='score_rank' defaultMessage='Score & rank' />
                                                </span>
                                                <div>
                                                    <span className="rank-points">{userDetails.totalPoints}</span>
                                                    <img className="score-rank-star" src={require('./../../assets/images/icons/favorite.svg')} alt="star" />
                                                </div>
                                            </div>
                                            <div className="score-rank-content">

                                                <img src={require('./../../assets/images/icons/trophy.svg')} alt="trophy" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {url.indexOf('demo') >= 0 ?
                                    <div className="reset-button" onClick={this.handleDeleteUser}>
                                        <span>Reset</span>
                                    </div> :
                                    <div className="only-for-mobile">
                                        <div className="unsubscribe-section">
                                            <span className="unsubscribe-content"><FormattedMessage id = {config.subDomain === 'gh' ? 'cancel-subscribtion-2':'cancel-subscribtion'} defaultMessage={config.subDomain === 'gh' ? 'To unsubscribe, dial *713*51# or click here' :'To cancel your membership please click here'}/></span>
                                            <span className="unsubscribe-button" onClick={this.handleUnsubscription}><FormattedMessage id='unsub-btn' defaultMessage='Unsubscribe' /></span>
                                        </div>
                                    </div>
                                }
                                <div className="last-predictions">
                                    <span className="header">
                                        <FormattedMessage id='last_predictions' defaultMessage='Last Predictions' />
                                    </span>
                                    <div className="last-predictions-content calendar-container">
                                        {predicatedData.length > 0 && matchesList.length > 0 ?
                                            matchesList.map((matchDetails, index) => {
                                                let isHomeTeamPredicted = findIndex(firstFourPrediction, { predicated_team_one_id: matchDetails.homeTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
                                                let isAwayTeamPredicted = findIndex(firstFourPrediction, { predicated_team_two_id: matchDetails.awayTeam.team_id, matchid: matchDetails.fixture_id }) >= 0 ? true : false;
                                                if (isHomeTeamPredicted || isAwayTeamPredicted) {
                                                    console.log("check")
                                                    return <UpcomingMacthesCard handlePrediction={this.handlePrediction} matchDetails={matchDetails} predicatedData={firstFourPrediction} />
                                                }
                                            }) :
                                            <div className={`last-predictions-blank calendar-container ${config.subDomain === 'gh' ? 'gh-style':''}`}>
                                                <span>
                                                    <FormattedMessage id='last-predictions-empty-message' defaultMessage='Predictions will be available after the start of the season.' />
                                                </span>
                                            </div>
                                        }

                                    </div>
                                </div>
                                {
                                    config.subDomain === 'gh' &&
                                    <div className='subscribe_instruction_section'>
                                        {
                                            ghSubscriptionInst.map((data) => (
                                                <Accordion subscriptionInstruct={data} />
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            <div className="unsubscribe-section">
                                <span className="unsubscribe-content"><FormattedMessage id={config.subDomain === 'gh' ? 'cancel-subscribtion-2':'cancel-subscribtion'} defaultMessage={config.subDomain === 'gh' ? 'To unsubscribe, dial *713*51# or click here' :'To cancel your membership please click here'} /></span>
                                <span className="unsubscribe-button" onClick={this.handleUnsubscription}><FormattedMessage id='unsub-btn' defaultMessage='Unsubscribe' /></span>
                            </div>
                        </div>
                        :
                        <EditProfile
                            handleChange={this.handleChange}
                            handleRouting={this.handleRouting}
                            fields={RegistrationReducer.userDetails}
                            errors={errors}
                            countryList={RegistrationReducer.countryList}
                            isSaving={isSaving}
                        />
                    }
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        RegistrationReducer: state.RegistrationReducer,
        selectedLanguage: state.HeaderReducer.selectedLanguage,
        matchesList: state.CalendarReducer.matchesList,
        CalendarReducer: state.CalendarReducer
    }
}
const mapDispatchToProps = dispatch => {
    return {
        selectLanguage: bindActionCreators(selectLanguage, dispatch),
        editProfile: bindActionCreators(editProfile, dispatch),
        getPredictions: bindActionCreators(getPredictions, dispatch),
        getMatchDetails: bindActionCreators(getMatchDetails, dispatch),
        addPrediction: bindActionCreators(addPrediction, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
