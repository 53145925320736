import * as action from './action_types';

export function getCountryList() {
    return {
        type: action.GET_COUNTRY_LIST_FETCH,
    }
};


export function checkUserAuth(uid,type) {
    return {
        type: action.GET_USER_ACCESS_FETCH,
        data:{uid:uid,type:type}
    }
};

export function getUserDetails() {
    return {
        type: action.GET_USER_DETAILS_FETCH
        }
};
export function setUserDetails(data) {
    return {
        type: action.GET_USER_DETAILS_SUCCESS,
        data:data
        }
};
export function editProfile(data) {
    return {
        type: action.EDIT_USER_DETAILS_SUCCESS,
        data:data,
        meta: {
            thunk: true
          }
        }
};

export function getLeaguesList(season) {
    return {
        type: action.GET_LEAGUE_LIST_FETCH,
        season:season
        }
};
export function changeLeague(data) {
    return {
        type: action.CHANGE_LEAGUE,
        data:data
        }
};

