//without text input
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/fr';
import 'moment/locale/ar';

import 'react-day-picker/lib/style.css';
import {MONTHS,WEEKDAYS_SHORT} from './../../translations/calenderTranslations'
import './formElements.scss';

class DatePicker extends Component {
    constructor(props){
        super(props);
        this.state={
            isVisible:false,
            selectedDay:new Date(),
            modifiers:{
                highlighted: new Date()
              }
        }
        this.dayPickerId = "complicated-daypicker-container";
    }
    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside)
    }
    
    handleClickOutside = event =>{
        let dialogClick = false;
        //for mozilla
        //https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js
        let path = event.path || (event.composedPath && event.composedPath());
        path.every(element =>{
            if(element.id ===this.dayPickerId){
                dialogClick= true;
                return false;
            }
            return true;
        })

        if(!dialogClick){ // it clicked on outside the dialog
            this.setState({
                isVisible:false
            })
        }
      }
    toggleDatePicker=()=>{
        this.setState({
            isVisible:!this.state.isVisible
        })
    }
    handleDayClick=(day, modifir={})=>{
        if (modifir.disabled) {
            return;
          }
        const {getSelectedDate} =this.props
        let modifiers=this.state.modifiers;
        modifiers.highlighted=day;
        getSelectedDate(day)
        this.setState({
            modifiers,
            isVisible:false
        })
    }
    render() {
        const {isVisible,selectedDay,modifiers} =this.state;
        const {disabledDays,getSelectedDate,title,locale} =this.props
        return (
            <div id={this.dayPickerId}>
                <div className="form-datepicker" onClick={this.toggleDatePicker}>
                    <span>{title}</span>
                    <img className="downArrow" src={require('./../../assets/images/icons/down-arrow.svg')} alt="down-arrow" />
                </div>
                {isVisible && 
                <div className="date-picker">
                     <DayPicker
                    locale={locale}
                    localeUtils={MomentLocaleUtils}
                     modifiers={modifiers}
                      disabledDays={[
                        disabledDays
                    ]}
                    month={modifiers.highlighted}
                    onDayClick={this.handleDayClick}
                      />
                </div>
               }
            </div>
        )
    }
};

DatePicker.propTypes = {
    text: PropTypes.any,
    getSelectedDate:PropTypes.func,
    closePicker:PropTypes.func,
    title:PropTypes.string,
    locale:PropTypes.string
    
}

export default DatePicker;