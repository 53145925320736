//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,SimpleTextbox,ErrorMessage} from './../form-elements';
import { FormattedMessage } from 'react-intl';

import ComponentWrapper from './componentWrapper'
import Loader from './../core/loader/loader';
import './login.scss';

const NickName = ({handleRouting,handleChange,fields,errors,isSaving}) => (
    <ComponentWrapper heading={
        <FormattedMessage id='nickname' defaultMessage='Nickname'/>
    } subheading={
        <FormattedMessage id='enter_your_nickname_right_here' defaultMessage='Enter your nickname right here'/>
    }>
        <div className="login-form">
            <SimpleTextbox placeholder={
                <FormattedMessage id='nickname' defaultMessage='Nickname'/>
            } 
                type="text"
                name="name"
                value={fields['name'] || ''}
                onChange={handleChange}
                IconComponent={
                        ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                    }
            />
            <ErrorMessage errorMessage={errors['name']}/>
            <Simplebutton onClick={()=>handleRouting('/login/leagues')} isDisabled={isSaving}
             text={isSaving ? null :
                <FormattedMessage id='continue' defaultMessage='Continue'/>}
             isPrimary={false} customStyle={'login-inputbox'}>
            {isSaving ? <Loader customStyle={'loader-size'}/>:   
                <img className="login-icon" src={require('./../../assets/images/icons/right-arrow.svg')} alt="login-icon"/>
             }
            </Simplebutton>
        </div>
    </ComponentWrapper>
);

NickName.defaultProps={
}

NickName.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
}

export default NickName;