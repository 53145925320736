export const GET_COUNTRY_LIST_FETCH = 'GET_COUNTRY_LIST_FETCH';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAIL = 'GET_COUNTRY_LIST_FAIL';

export const GET_USER_ACCESS_FETCH = 'GET_USER_ACCESS_FETCH';
export const GET_USER_ACCESS_SUCCESS = 'GET_USER_ACCESS_SUCCESS';
export const GET_USER_ACCESS_FAIL = 'GET_USER_ACCESS_FAIL';

export const GET_USER_DETAILS_FETCH = 'GET_USER_DETAILS_FETCH';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const SET_USER_DETAILS_FETCH = 'SET_USER_DETAILS_FETCH';


export const EDIT_USER_DETAILS_FETCH = 'EDIT_USER_DETAILS_FETCH';
export const EDIT_USER_DETAILS_SUCCESS = 'EDIT_USER_DETAILS_SUCCESS';
export const EDIT_USER_DETAILS_FAIL = 'EDIT_USER_DETAILS_FAIL';

export const GET_LEAGUE_LIST_FETCH = 'GET_LEAGUE_LIST_FETCH';
export const GET_LEAGUE_LIST_SUCCESS = 'GET_LEAGUE_LIST_SUCCESS';
export const GET_LEAGUE_LIST_FAIL = 'GET_LEAGUE_LIST_FAIL';

export const CHANGE_LEAGUE = 'CHANGE_LEAGUE';

