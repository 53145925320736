//without text input
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Simplebutton, SimpleTextbox } from './../form-elements'

import './login.scss';

class ComponentWrapper extends Component {

    render() {
        const { heading, subheading, children,selectedImage } = this.props;
        return (
            <div className="login-actions">
                <span className="welcom-heading">
                    {heading}
                </span>
                <div className="welcome-subheading">
                    <span>
                        {subheading}
                    </span>
                    {/* <img className="welcome-downarrow" src={require('./../../assets/images/icons/arrow-green-down.svg')} alt="welcome-downarrow" /> */}
                </div>
                {children}
               {selectedImage ? 
               <div className="wrapper-image">
                   <img className="image" src={selectedImage}/>
               </div>
               :null}
            </div>
        )
    }
}

ComponentWrapper.defaultProps = {
    selectedImage:undefined
}

ComponentWrapper.propTypes = {
    selectedImage:PropTypes.string
}

export default ComponentWrapper;