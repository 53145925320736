import React from "react";
import "./videos.scss";
import VideosData from "./videos.json";
import VideoCard from "./VideoCard";
import { Row, Col } from "react-simple-flex-grid";

class VideoPage extends React.Component {
  render(){
    return (
      <div className="video-page">
        <div className="video-page-title">Trending Videos</div>
        <div className="video-container">
          <Row
            gutter={10}
            style={{
              marginTop: "1rem",
            }}
          >
            {VideosData.map((item) => {
              return (
                <Col key={item.id} xs={6} sm={4} md={4} lg={3} xl={3}>
                  <VideoCard key={item.id} {...item} onClick={
                    () => this.props.history.push("/play/" + item.id)
                  } />
              </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
};

export default VideoPage;
