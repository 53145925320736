import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/configureStore';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/ar';
import { ToastContainer } from 'react-toastify';
import 'react-simple-flex-grid/lib/main.css';


import Login from './components/Login/login';
import Welcome from './components/Login/welcome';
import DashboardLayout from './components/Home/dashboardLayout';
import {checkUserAuth,getUserDetails,getCountryList,setUserDetails,getLeaguesList} from './redux/actions/registration/action'
import { selectLanguage } from './redux/actions/header/action';

import Privateroute from './utils/privateroute';
import history from './utils/history';
import SplashScreen from './components/splashScreen/splashScreen';
import API from './lib/api';
import {appendUid} from './utils/helper'

import {IntlProvider} from 'react-intl';
import { addLocaleData } from "react-intl";
import { config } from './constants/domainConfig';

import locale_en from 'react-intl/locale-data/en';
import locale_fr from 'react-intl/locale-data/fr';
import locale_ar from 'react-intl/locale-data/ar';


import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import messages_ar from "./translations/ar.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import getSearchParams from './utils/getSearchParams';

addLocaleData([...locale_en, ...locale_ar, ...locale_fr]);

const messages = {
    'en': messages_en,
    'fr': messages_fr,
    'ar': messages_ar
};


class App extends Component {
    constructor(props){
        super(props);
        this.state={
            isFetching:false
        }
    }

    componentWillMount(){
        const {location,getUserDetails,getCountryList,getLeaguesList,history,selectLanguage,setUserDetails}=this.props
        var url_string = window.location.href;
        var url = new URL(url_string);
        var uid = getSearchParams('uid');
        var msisdn = getSearchParams('msisdn');
        selectLanguage(config.lang);
        // getCountryList();
        var cDate = new Date();
        var cYear = cDate.getFullYear();
        getLeaguesList(2023); // fix for now - check back
        if (sessionStorage.getItem('loggedIn')) {
            let isLoggedIn = false;
            isLoggedIn = JSON.parse(sessionStorage.getItem('loggedIn')).loggedIn;
            if(isLoggedIn){
                getUserDetails();
                if(location.pathname.indexOf("welcome")>0 || location.pathname.indexOf("login")>0){
                    this.props.history.push(appendUid('/',uid))    
                }else{
                    this.props.history.push(appendUid(location.pathname,uid))    
                }
            }
        }
        const is_staging = getSearchParams('is_staging');
        if(uid && is_staging === "true"){
            this.setState({
                isFetching:true
            })
            API.checkUserAuthStaging(uid)
            .then(authData=>{
                if(authData.success){
                    sessionStorage.setItem("token",JSON.stringify({token:authData.data.token,success:authData.success}));
                    sessionStorage.setItem('rockmanId', authData.data.payload.rockmanId)
                    sessionStorage.setItem("staging", true)
                    API.getUserDetails().then(userDetails=>{
                        if(userDetails.success){
                            setUserDetails(userDetails)
                        }
                        setTimeout(()=>{
                            this.setState({
                                isFetching:false
                            },()=>{
                                if(userDetails.data.isRegCompleted){
                                    sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
                                    history.push(appendUid('/',uid))
                                }else{
                                    history.push(appendUid('/login/language',uid))
                                }
                            })
                        },2000)
                       
                       
                    })
                }else{
                    setTimeout(()=>{
                    this.setState({
                        isFetching:false
                    },()=>{
                        history.push('/welcome')
                    })},2000)
                }
            
            })
            .catch(err=>{
                console.log(err)
            });
        }
        else if(uid){
            this.setState({
                isFetching:true
            })
            API.checkUserAuth(uid,'uid')
            .then(authData=>{
                if(authData.success){
                    sessionStorage.setItem("token",JSON.stringify({token:authData.data.token,success:authData.success}));
                    sessionStorage.setItem('rockmanId', authData.data.payload.rockmanId)
                    API.getUserDetails().then(userDetails=>{
                        if(userDetails.success){
                            setUserDetails(userDetails)
                        }
                        setTimeout(()=>{
                            this.setState({
                                isFetching:false
                            },()=>{
                                if(userDetails.data.isRegCompleted){
                                    sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
                                    history.push(appendUid('/',uid))
                                }else{
                                    history.push(appendUid('/login/language',uid))
                                }
                            })
                        },2000)
                       
                       
                    })
                }else{
                    setTimeout(()=>{
                    this.setState({
                        isFetching:false
                    },()=>{
                        history.push('/welcome')
                    })},2000)
                }
            
            })
            .catch(err=>{
                console.log(err)
            });
        }
        else if(msisdn && msisdn.length > 0){
            this.setState({
                isFetching:true
            })
            API.checkUserAuth(msisdn,'mobile')
            .then(authData=>{
                if(authData.success){
                    sessionStorage.setItem("token",JSON.stringify({token:authData.data.token,success:authData.success}));
                    sessionStorage.setItem('rockmanId', authData.data.payload.rockmanId)
                    API.getUserDetails().then(userDetails=>{
                        if(userDetails.success){
                            setUserDetails(userDetails)
                        }
                        setTimeout(()=>{
                            this.setState({
                                isFetching:false
                            },()=>{
                                if(userDetails.data.isRegCompleted){
                                    sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
                                    history.push(appendUid('/',uid))
                                }else{
                                    history.push(appendUid('/login/language',uid))
                                }
                            })
                        },2000)
                       
                       
                    })
                }else{
                    setTimeout(()=>{
                    this.setState({
                        isFetching:false
                    },()=>{
                        history.push('/welcome')
                    })},2000)
                }
            
            })
            .catch(err=>{
                console.log(err)
            });
        }
    }
       UNSAFE_componentWillReceiveProps(nextProps){
        //    const {RegistrationReducer,history} =nextProps;
        //    const accessData=RegistrationReducer.accessData
        // const nextData=nextProps.RegistrationReducer;
        // const prevData=this.props.RegistrationReducer;
        // if(nextData.accessData.success!==prevData.accessData.success){
        //     if(accessData.userDetails.isRegCompleted){
        //         sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
        //         history.push('/')
        //     }else{
        //         history.push('/login/nickname')
        //     }
        // }
      }

      componentDidMount(){
            // Detects if device is on iOS 
            const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test( userAgent );
            }
            // Detects if device is in standalone mode
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

                // Checks if should display install popup notification:
                if (isIos() && !isInStandaloneMode()) {
                this.setState({ showInstallMessage: true });
            }
      }

   render() {
       const {RegistrationReducer,selectedLanguage} =this.props;
       const accessData=RegistrationReducer.accessData;
       moment.locale(selectedLanguage);
        return (
            <IntlProvider locale={selectedLanguage} messages={messages[selectedLanguage]}>
            <div>
                    {this.state.isFetching || this.props.RegistrationReducer.dropDownLeagueslist.length<=0  ? <SplashScreen/>:
                    <Switch>
                        <Route path="/welcome" component={Login} />
                        <Route path="/login/:active" component={Login} />
                        <Privateroute component={DashboardLayout} />
                    </Switch>}
                    <ToastContainer />
            </div>
         </IntlProvider>

        );


    }
}

const mapStateToProps = state => {
    return{
        RegistrationReducer: state.RegistrationReducer,
        selectedLanguage: state.HeaderReducer.selectedLanguage,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        checkUserAuth: bindActionCreators(checkUserAuth, dispatch),
        getUserDetails: bindActionCreators(getUserDetails, dispatch),
        getCountryList: bindActionCreators(getCountryList, dispatch),
        selectLanguage: bindActionCreators(selectLanguage, dispatch),
        setUserDetails: bindActionCreators(setUserDetails, dispatch),
        getLeaguesList: bindActionCreators(getLeaguesList, dispatch)
       
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
