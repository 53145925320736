import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './teamDetails.scss';
import TeamStatistics from './teamStatistics';
import PlayerTransfers from './playerTransfers';
import TeamPlayers from './teamPlayers';
import {appendUid,getUid} from './../../utils/helper'
import {getTeamStats,getTeamPlayers,getTeamTransfers,getTeamDetails} from './../../redux/actions/livescore/action';
import { sendData } from '../../analytics/sendData';

class TeamDetails extends Component {
    constructor(props) {
        super(props);
            this.state={
                showColumns:false
            }
        }
        componentWillMount(){
            const {RegistrationReducer,getTeamStats,getTeamTransfers,getTeamPlayers,getTeamDetails}=this.props;
            const {params}=this.props.match;
            let teamId=params.teamId.indexOf('?')>=0? params.teamId.substring(0,params.teamId.indexOf('?')):params.teamId
            const leagueId= RegistrationReducer.selectedLeage.id;
            getTeamDetails(teamId);
            getTeamStats(leagueId,teamId);
            getTeamTransfers(teamId);
            getTeamPlayers(teamId,'2018');
        }
        componentDidMount() {
            sendData('', '', 'football match' , 'viewed');
        }
    
        handlePlayerDetails=(id)=>{
            
        }
        selectDotTwo=()=>{
            this.setState({
                showColumns:true
            })
        }
        selectDotOne=()=>{
            this.setState({
                showColumns:false
            })
        }
        handleGoBack=()=>{
            const {match}=this.props;
            let back=match.params.back,path;
            if(back==='live'){
                path='/'
            }else{
                path="/calendar"
            }
            this.props.history.push(appendUid(path,getUid()))
        }
    render() {
        const {uid,LivescoreReducer,RegistrationReducer}=this.props;
        const {teamPlayers,teamStats,teamTransfers,teamDetails}=LivescoreReducer;
        const {selectedLeage} =RegistrationReducer;
        const selectedTeamData=teamDetails;
        return (
            <div className="team-container">
                <div className="video-highlits"></div>
                <div className="team-details-container">
                    <div className="team-heading" >
                        <img src={teamDetails.logo} className="team-logo" onClick={this.handleGoBack}/>
                        <span className="team-name">{teamDetails.name}</span>
                    </div>
                    <div className="team-details-row">
                        <div className="col col-one-width">
                            <TeamStatistics teamStats={teamStats} selectedTeamData={teamDetails} selectedLeage={selectedLeage}/>
                            {teamTransfers.length >0 ?<PlayerTransfers teamTransfers={teamTransfers}/>:null}
                        </div>
                        <div className="col col-two-width">
                            <TeamPlayers isVisibleForPC={window.screen.width > 768} selectDotTwo={this.selectDotTwo} selectDotOne={this.selectDotOne} showColumns={this.state.showColumns}
                            teamPlayers={teamPlayers} uid={uid} teamLogo={teamDetails.logo} teamName={teamDetails.name}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


TeamDetails.defaultProps = {

}

TeamDetails.propTypes = {
    selectedTeamData:PropTypes.object,
    selectedLeage:PropTypes.object,
    toggleTeamDetails:PropTypes.func,
    getTeamStats:PropTypes.func,
    getTeamTransfers:PropTypes.func,
    getTeamPlayers:PropTypes.func
}
const mapDispatchToProps = dispatch => {
    return {
        getTeamStats: bindActionCreators(getTeamStats, dispatch),
        getTeamTransfers: bindActionCreators(getTeamTransfers, dispatch),
        getTeamPlayers: bindActionCreators(getTeamPlayers, dispatch),
        getTeamDetails: bindActionCreators(getTeamDetails, dispatch)
    }
}

const mapStateToProps = state => {
    return {
        LivescoreReducer: state.LivescoreReducer,
        RegistrationReducer: state.RegistrationReducer,
        matchesList: state.CalendarReducer.matchesList,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);