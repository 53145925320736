import React, { Component } from 'react';
// import './login.scss';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';


const RankingTableWithGroup=({poolRankingList,showTeamDetails})=>{
    return(
        poolRankingList.map((rowData, index) => {
            return (
                <table className="group-standing">
                    <tr>    
                        <th className="group-name">{rowData[0].group}</th>
                        <div className="group-content group-head">
                            <th>W</th>
                            <th>D</th>
                            <th>L</th>
                            <th>S</th>
                        </div>
                    </tr>
                    {rowData.map((groupData, index) => {
                        return (
                            // <Link to={'/teamDetails/'+groupData.team_id}>
                            <tr className="team-content" onClick={()=>showTeamDetails(groupData)}>
                                {/* <td>
                                </td> */}
                                <td className="team-name">
                                    <span className="team-srno">{groupData.rank}.</span>
                                    {groupData.teamName === "South Sudan" ?
                                        <img src={require('./../../assets/images/south_sadan.png')}  className="team-flag"/>:
                                        <img src={groupData.logo}  className="team-flag"/>
                                                }
                                     <span style={{verticalAlign: 'super'}}>{groupData.teamName}</span>
                                </td>
                                <div className="group-content">
                                    <td>{groupData.all.win}</td>
                                    <td>{groupData.all.draw}</td>
                                    <td>{groupData.all.lose}</td>
                                    <td>{groupData.points}</td>
                                </div>
                            </tr>
                            // </Link>
                        )
                    })}
                </table>
            )
        
        
        })
    )
    }

RankingTableWithGroup.prototype={
    poolRankingList:PropTypes.array,
}
export default RankingTableWithGroup;

