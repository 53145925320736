import { getSubdomain } from '../utils/helper';

export let config = {
    lang: 'en',
    country: '',
    subDomain: '',
    countryCode: '',
    msisdnLength: 10
}

const subDomain = getSubdomain();

if (subDomain === 'demo' ) {
    config.lang = "en";
    config.subDomain = "my";
    config.country = "xx";
    config.countryCode = "";
    config.msisdnLength = 9;
}
else if (subDomain === 'eg') {
    config.lang = "ar";
    config.subDomain = "eg";
    config.country = "eg";
    config.countryCode = "20";
    config.msisdnLength = 10;
}
else if (subDomain === 'fr' ) {
    config.lang = "fr";
    config.subDomain = "dz";
    config.country = "fr";
    config.countryCode = "";
    config.msisdnLength = 10;
}
else if (subDomain === 'dz' ) {
    config.lang = "fr";
    config.subDomain = "dz";
    config.country = "dz";
    config.countryCode = "";
    config.msisdnLength = 10;
}
else if (subDomain === 'za' ) {
    config.lang = "en";
    config.subDomain = "za";
    config.country = "za";
    config.countryCode = "27";
    config.msisdnLength = 9;
}
else if (subDomain === 'ci' ) {
    config.lang = "fr";
    config.subDomain = "ci";
    config.country = "ci";
    config.countryCode = "225";
    config.msisdnLength = 8;
}
else if (subDomain === 'cm') {
    config.lang='fr';
    config.subDomain = "cm";
    config.country = "cm";
    config.countryCode= "237";
    config.msisdnLength= 9;
}
else if (subDomain === 'gh' || subDomain.includes('localhost')) {
    config.lang = "en";
    config.subDomain = "gh";
    config.country = "gh";
    config.countryCode = "233";
    config.msisdnLength = 9;
}
else if (subDomain === 'uk' ) {
    config.lang = "en";
    config.subDomain = "uk";
    config.country = "uk";
    config.countryCode = "44";
    config.msisdnLength = 10;
}
else if (subDomain === 'sa' ) {
    config.lang = "ar";
    config.subDomain = "sa";
    config.country = "sa";
    config.countryCode = "682";
    config.msisdnLength = 10;
}
else {
    config.lang = "en";
    config.subDomain = "demo";
    config.country = "xx";
    config.countryCode = "";
    config.msisdnLength = 10;
}