import * as action from './action_types';

export function getGroupRanking(leauge_id) {
    return {
        type: action.POOL_RANKING_FETCH,
        id:leauge_id
    }
};

export function getTeamStats(leauge_id,team_id) {
    return {
        type: action.GET_TEAM_STATS_FETCH,
        leauge_id,
        team_id
    }
};
export function getTeamTransfers(team_id) {
    return {
        type: action.GET_TEAM_TRANSFERS_FETCH,
        team_id
    }
};
export function getTeamPlayers(team_id,season) {
    return {
        type: action.GET_TEAM_PLAYERS_FETCH,
        team_id,
        season
    }
};
export function getLiveScore(leauge_id) {
    return {
        type: action.GET_LIVE_SCORE_FETCH,
        id:leauge_id
    }
};

export function setLiveScore(data) {
    return {
        type: action.GET_LIVE_SCORE_SUCCESS,
        data:data
    }
};

export function setTimerTime(data) {
    return {
        type: action.SET_TIMER_TIME,
        data:data
    }
};
export function getPlayersDetailsById(playerId) {
    return {
        type: action.GET_PLAYER_BY_ID_FETCH,
        playerId:playerId
    }
};
export function getTeamDetails(team_id) {
    return {
        type: action.GET_TEAM_DETAILS_FETCH,
        team_id:team_id
    }
};