import * as action_type from './../actions/header/action_types';
const initialState = {
    activeMenu: 'livescore',
    selectedLanguage:null
}
const HeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.SELECT_MENU_ITEM:
            return Object.assign({}, state, {
                activeMenu: action.item
            });
        case action_type.SELECT_LANGUAGE:
            console.log("action.lang",action.lang)
            return Object.assign({}, state, {
                selectedLanguage: action.lang
            });
        default:
            return state;
    }
}

export default HeaderReducer;