import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

import { FormattedMessage } from 'react-intl';
import {getUid} from './../../utils/helper'


const PlayerBasicDetails = ({ playerDetails, teamLogo,selectedLanguage }) => {
    let playerShortName = playerDetails.player_name.split(" ");
    return (
        window.screen.width <= 768 ?
            <div className="players-basic-details-mobile">
                <p className="player-name-mobile">{playerShortName.length >= 2 ? `${playerShortName[0]} ${playerShortName[1]}` : playerDetails.player_name}</p>
                <div className="player-position-container">
                    <div className={`${selectedLanguage==='ar'?'rtl-raduis' :'raduis'} player-position-mobile`}>{playerDetails.position ? `${playerDetails.position}` : "Not available"}</div>
                    <div className="team-logo-container-mobile">
                        <Link to={`/?uid=${getUid()}`}>
                            <img className="team-logo-mobile" src={teamLogo} />
                        </Link>
                    </div>
                </div>

                <div className="player-basic-info-card-mobile">
                    <div>
                       {playerDetails.age ? <div className="player-basic-info">
                            <span className="title"> <FormattedMessage id='age' defaultMessage='Age' />:</span>
                            <span className="value">{playerDetails.age ? `${playerDetails.age} years old` : "Not available"}</span>
                        </div>:null}
                        {playerDetails.height ?  <div className="player-basic-info">
                            <span className="title"> <FormattedMessage id='height' defaultMessage='Height' />:</span>
                            <span className="value">{playerDetails.height ? `${playerDetails.height}` : "Not available"}</span>
                        </div>:null}
                        {playerDetails.weight ? <div className="player-basic-info">
                            <span className="title"><FormattedMessage id='weight' defaultMessage='Weight' />:</span>
                            <span className="value">{playerDetails.weight ? `${playerDetails.weight}` : "Not available"} </span>
                        </div>:null}
                        {playerDetails.nationality ? <div className="player-basic-info">
                            <span className="title"><FormattedMessage id='nationality' defaultMessage='Nationality' />:</span>
                            <span className="value">{playerDetails.nationality ? `${playerDetails.nationality}` : "Not available"}  France</span>
                        </div>:null}
                    </div>
                    <div className="player-number-container">
                        {playerDetails.number ? <span className="player-number">{playerDetails.number}</span> : null}
                    </div>
                </div>
            </div> :
            <div className="players-basic-details">
                {playerDetails.number ? <span className="player-number">{playerDetails.number}</span> : null}
                <p className="player-name">{playerShortName.length >= 2 ? `${playerShortName[0]} ${playerShortName[1]}` : playerDetails.player_name}</p>
                <p className="player-percentage">{playerDetails.rating ? `${playerDetails.rating}` : null}</p>
                <div className="player-position">{playerDetails.position ? `${playerDetails.position}` : "Not available"}</div>
                <div className="player-detais">
                        {playerDetails.age ? <div className="player-basic-info">
                            <span className="title"> <FormattedMessage id='age' defaultMessage='Age' />:</span>
                            <span className="value">{playerDetails.age ? `${playerDetails.age} years old` : "Not available"}</span>
                        </div>:null}
                        {playerDetails.height ?  <div className="player-basic-info">
                            <span className="title"> <FormattedMessage id='height' defaultMessage='Height' />:</span>
                            <span className="value">{playerDetails.height ? `${playerDetails.height}` : "Not available"}</span>
                        </div>:null}
                        {playerDetails.weight ? <div className="player-basic-info">
                            <span className="title"><FormattedMessage id='weight' defaultMessage='Weight' />:</span>
                            <span className="value">{playerDetails.weight ? `${playerDetails.weight}` : "Not available"} </span>
                        </div>:null}
                        {playerDetails.nationality ? <div className="player-basic-info">
                            <span className="title"><FormattedMessage id='nationality' defaultMessage='Nationality' />:</span>
                            <span className="value">{playerDetails.nationality ? `${playerDetails.nationality}` : "Not available"}  France</span>
                        </div>:null}
                    <div className={`team-logo-container ${selectedLanguage==='ar' ? 'right':'left'}`}>
                       <Link to={`/?uid=${getUid()}`}>
                            <img className="team-logo" src={teamLogo} />
                        </Link> 
                    </div>
                </div>
            </div>
    )
}


PlayerBasicDetails.defaultProps = {

}

PlayerBasicDetails.propTypes = {
    selectedLanguage:PropTypes.string
}

export default PlayerBasicDetails;