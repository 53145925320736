import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom'

import TeamDetailsWrapper from './teamDetailsWrapper';
import {appendUid,} from './../../utils/helper'
import {playerShortHandName} from './../../utils/dataParser'


const TeamPlayers = ({ teamPlayers, uid ,teamLogo,teamName,selectDotOne,selectDotTwo,showColumns,isVisibleForPC}) => {

    return (
        <div className="team-players-container">
            <TeamDetailsWrapper heading={teamName}>
            
                <div className="team-players-stats">
                
                    <div className="row">
                        <div className="col-one">
                            <div className="dots-container">
                                <div className={!showColumns? `selected-dot` : `not-selected-dot`} onClick={selectDotOne}></div>
                                <div className={showColumns ? `selected-dot` : `not-selected-dot`} onClick={selectDotTwo}></div>
                            </div>
                        </div>
                        <div className="col-two">
                            {!showColumns ||isVisibleForPC ?<span className="head">
                                <FormattedMessage id='goals' defaultMessage='Goals' />
                            </span>:null}
                            {!showColumns || isVisibleForPC?<span className="head">
                                <FormattedMessage id='assist' defaultMessage='Assist' />
                            </span>:null}
                            {showColumns || isVisibleForPC?<span className="head">
                                <FormattedMessage id='yel' defaultMessage='Yel' />
                            </span>:null}
                            {showColumns || isVisibleForPC?<span className="head">
                                <FormattedMessage id='red' defaultMessage='Red' />
                            </span>:null}
                            {showColumns || isVisibleForPC?<span className="head">
                                <FormattedMessage id='PS%' defaultMessage='PS%' />
                            </span>:null}
                        </div>
                    </div>
                    <div className="players-details">
                        {teamPlayers.length > 0 ?
                            teamPlayers.map((rowData, index) => {
                                return (
                                    <div className="row" key={rowData.player_name + index}>
                                        <Link className="col-one player-name" to={{ pathname: appendUid(`/playerDetails/${rowData.team_id}/${rowData.league}/${rowData.player_id}`,uid), state: { playerDetails: rowData,teamLogo:teamLogo} }}>
                                        <span className="srNo">{`${index + 1}.`}</span>&nbsp;<span>{`${playerShortHandName(rowData.player_name)}`}</span>
                                        </Link>
                                        <div className="col-two">
                                            {!showColumns || isVisibleForPC?<span className="box">{rowData.goals.total}</span>:null}
                                            {!showColumns || isVisibleForPC?<span className="box">{rowData.goals.assists}</span>:null}
                                            {showColumns || isVisibleForPC?<span className="box">{rowData.cards.yellow}</span>:null}
                                            {showColumns || isVisibleForPC?<span className="box">{rowData.cards.red}</span>:null}
                                            {showColumns || isVisibleForPC  ?<span className="box">0</span>:null}
                                        </div>
                                    </div>
                                )
                            })
                            : <div className="empty-data">
                                <p>Data not available</p>
                            </div>}
                    </div>
                </div>
            </TeamDetailsWrapper>
        </div>
    )
}


TeamPlayers.defaultProps = {

}

TeamPlayers.propTypes = {
    teamPlayers: PropTypes.array,
    teamLogo:PropTypes.string,
    teamName:PropTypes.string
}

export default TeamPlayers;