import * as action_type from './../actions/registration/action_types';
import {SELECT_LANGUAGE} from './../actions/header/action_types';
import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from './../../lib/api';


function* getCountryList(action) {
    try {
        const data = yield API.getCountryList();
        yield put({ type: action_type.GET_COUNTRY_LIST_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getLeaguesList(action) {
    try {
        const data = yield API.getLeaguesList(action.season);
        yield put({ type: action_type.GET_LEAGUE_LIST_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* checkUserAuth(action) {
    try {
        yield put({ type: action_type.GET_USER_DETAILS_FETCH})
        const data = yield API.checkUserAuth(action.data.uid,action.data.type);
        sessionStorage.setItem("token",JSON.stringify({token:data.data.token,success:data.success}));
        if(data.success){
            let userData = yield API.getUserDetails();
            if(userData.data.isRegCompleted){
                sessionStorage.setItem("loggedIn",JSON.stringify({loggedIn:true}));
            }
            data.userDetails=userData.data
        }
        yield put({ type: action_type.GET_USER_ACCESS_SUCCESS,data})
    }
    catch (e) {
        console.log(e);
    }
}
function* getUserDetails(action) {
    try {
        const data = yield API.getUserDetails();
        yield put({ type: action_type.GET_USER_DETAILS_SUCCESS,data})
        if(data.data.language){
            yield put({ type:SELECT_LANGUAGE,lang:data.data.language})
        }
    }
    catch (e) {
        console.log(e);
    }
}

// function* editProfile(action) {
//     try {
//         const data = yield API.editProfile(action.data);
//         yield put({ type: action_type.EDIT_USER_DETAILS_SUCCESS,data})
//     }
//     catch (e) {
//         console.log(e);
//     }
// }



export const RegistrationSaga = [
    takeEvery(action_type.GET_COUNTRY_LIST_FETCH, getCountryList),
    takeEvery(action_type.GET_USER_ACCESS_FETCH, checkUserAuth),
    takeEvery(action_type.GET_USER_DETAILS_FETCH, getUserDetails),
    takeEvery(action_type.GET_LEAGUE_LIST_FETCH, getLeaguesList),
    // takeEvery(action_type.EDIT_USER_DETAILS_FETCH, editProfile),

]