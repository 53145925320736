import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import './login.scss';
import history from './../../utils/history';
import Header from './../core/header';
import Livescore from './../livescore/livescore';
import Calendar from './../calendar/calendar';
import Leaderboard from './../leaderboard/leaderboard';
import Profile from './../profile/profile';
import Leagues from './../leagues/leagues';
import PlayerDetails from './../playerDetails/playerDetails'
import TeamDetails from './../teamDetails/teamDetails'
import './dashboardLayout.scss';
import VideoPage from '../Videos/VideoPage';
import VideoPlayer from '../Videos/VideoPlayer';

class DashboardLayout extends Component {


    render() {
        const {selectedLanguage}=this.props;
        return (
            <div className={selectedLanguage==='ar' ? "dashboard-container-rtl":"dashboard-container"}> 
                <Header/>
                <Switch>
                    <Route path="/calendar" component={Calendar} />
                    <Route path="/leaderboard" component={Leaderboard} />
                    <Route path="/videos" component={VideoPage} />
                    <Route path="/play/:videoId" component={VideoPlayer} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/leagues" component={Leagues} />
                    <Route path="/playerDetails/:teamId/:leagueName/:playerId" component={PlayerDetails} />
                    <Route path="/teamDetails/:back/:teamId" component={TeamDetails} />
                    <Route path="/" component={Livescore} />
                </Switch>
           </div>

        )
    }

}

const mapStateToProps = state => {
    return {
        selectedLanguage: state.HeaderReducer.selectedLanguage,
    }
}

export default connect(mapStateToProps,null)(DashboardLayout);
