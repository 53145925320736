import {parsePhoneNumber,getCountryCallingCode} from 'libphonenumber-js';
import {findFlagUrlByIso2Code} from "country-flags-svg"

const parse=(number,country)=>{
    if(country === "xx") return number;
    try{
        const parsed=parsePhoneNumber(number,country.toUpperCase());
        return parsed.number.replace("+","");
    }
    catch(e){
        return number;
    }
}

const isValid=(number,country)=>{
    if(country === "xx") return true;
    try{
        const parsed=parsePhoneNumber(number,country.toUpperCase());
        return parsed.isPossible();
    }
    catch(e){
        return false;
    }
}

const getPlaceHolder=(country)=>{
    if(country === 'xx') return "Your Number";
    return getCountryCallingCode(country.toUpperCase()) + "xxxxxxxx";
}

const getFlag = (country) => {
    if(country === "xx") return null;
    else return findFlagUrlByIso2Code(country.toUpperCase());
}


export {parse,isValid,getPlaceHolder, getFlag}