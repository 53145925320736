import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment'

import './calendar.scss';
import history from './../../utils/history';
import { getMatchDetails,closeModal } from './../../redux/actions/calendar/action';
import Matches from './matches';
import {formateDate} from './../../utils/helper'
import CalendarModal from './calendarModal'
import { FormattedMessage } from 'react-intl';
import { sendData } from '../../analytics/sendData';


class Calendar extends Component {
    constructor(props){
        super(props);
        this.state={
            isModalOpen:true
        }
    }
    componentWillMount(){
        this.props.getMatchDetails(this.props.RegistrationReducer.selectedLeage.id);
        setTimeout(()=>{
            this.closeModal();
        },30000)
    }
    componentDidMount() {
        sendData('', '', 'football match' , 'viewed');
    }
    closeModal=()=>{
       this.props.closeModal();
    }
    render() {
        const {CalendarReducer}=this.props;
        return (
            <div className="calendar-container">
                <CalendarModal modalIsOpen={CalendarReducer.isModalOpen} closeModal={this.closeModal}/>
                <div className="calendar-for-pc">
                    <div className="calender-section">
                        <Matches headerText={
                            <FormattedMessage id='previous_results' defaultMessage='Previous Results'/>
                        }
                        cardType="previous"
                        disabledDates={{
                            after: new Date()
                        }}
                        />
                    </div>
                    <div className="calender-section">
                        <Matches headerText={
                            <FormattedMessage id='upcoming_matches' defaultMessage='Upcoming Matches'/>
                        } cardType="upcoming"
                        disabledDates={{
                            before: new Date()
                        }}
                        />
                    </div>
                </div>
                <div className="calendar-for-mobile">
                    <div className="calender-section">
                        <Matches headerText={
                            <FormattedMessage id='previous_results' defaultMessage='Previous Results'/>
                        }
                        cardType="mobile"
                        />
                    </div>
                </div>
            </div>
        )
    }

}

Calendar.prototypes={
}

const mapStateToProps = state => {
    return {
        CalendarReducer: state.CalendarReducer,
        selectedLanguage: state.HeaderReducer.selectedLanguage,
        RegistrationReducer: state.RegistrationReducer
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getMatchDetails: bindActionCreators(getMatchDetails, dispatch),
        closeModal: bindActionCreators(closeModal, dispatch),

    }
}
export default  connect(mapStateToProps,mapDispatchToProps)(Calendar);