import * as action_type from './../actions/livescore/action_types';
import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from './../../lib/api';


function* getGroupRanking(action) {
    try {
        const data = yield API.getGroupRanking(action.id);
        yield put({ type: action_type.POOL_RANKING_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getTeamStats(action) {
    try {
        const data = yield API.getTeamStats(action.leauge_id,action.team_id);
        yield put({ type: action_type.GET_TEAM_STATS_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getTeamTransfers(action) {
    try {
        const data = yield API.getTeamTransfers(action.team_id);
        yield put({ type: action_type.GET_TEAM_TRANSFERS_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getTeamPlayers(action) {
    try {
        const data = yield API.getTeamPlayers(action.team_id,action.season);
        yield put({ type: action_type.GET_TEAM_PLAYERS_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getLiveScore(action) {
    try {
        const data = yield API.getLiveScore();
        yield put({ type: action_type.GET_LIVE_SCORE_SUCCESS,data: data ,league_id:action.id})
    }
    catch (e) {
        console.log(e);
    }
}
function* getPlayersDetailsById(action) {
    try {
        const data = yield API.getPlayersDetailsById(action.playerId);
        yield put({ type: action_type.GET_PLAYER_BY_ID_SUCCESS,data: data})
    }
    catch (e) {
        console.log(e);
    }
}
function* getTeamDetails(action) {
    try {
        const data = yield API.getTeamDetails(action.team_id);
        yield put({ type: action_type.GET_TEAM_DETAILS_SUCCESS,data: data})
    }
    catch (e) {
        console.log(e);
    }
}

export const LivescoreSaga = [
    takeEvery(action_type.POOL_RANKING_FETCH, getGroupRanking),
    takeEvery(action_type.GET_LIVE_SCORE_FETCH, getLiveScore),
    takeEvery(action_type.GET_TEAM_STATS_FETCH, getTeamStats),
    takeEvery(action_type.GET_TEAM_TRANSFERS_FETCH, getTeamTransfers),
    takeEvery(action_type.GET_TEAM_PLAYERS_FETCH, getTeamPlayers),
    takeEvery(action_type.GET_PLAYER_BY_ID_FETCH, getPlayersDetailsById),
    takeEvery(action_type.GET_TEAM_DETAILS_FETCH, getTeamDetails)

]