//without text input
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TeamDetailsWrapper extends Component {

    render() {
        const { heading, children } = this.props;
        return (
            <div className="team-details-wrapper">
            <div>
                <h4 className="league-heading">{heading}</h4>
            </div>
            <div>
                {children}
            </div>
        </div>
        )
    }
}

TeamDetailsWrapper.defaultProps = {
}

TeamDetailsWrapper.propTypes = {
    heading:PropTypes.string,
    children:PropTypes.any
}

export default TeamDetailsWrapper;