import React, { Component } from 'react';

import './leagues.scss';
import history from './../../utils/history';
import { FormattedMessage } from 'react-intl';


class Leagues extends Component {
    render() {
        return (
            <div className="leagues">
                <div>
                    <h1>Coming soon.....</h1>
                </div>
                <div className="leagues-container">
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/world-cup.png')} />
                    </div>
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/1.png')} />
                    </div>
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/2.png')} />
                    </div>
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/3.png')} />
                    </div>
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/4.png')} />
                    </div>
                    <div className="league-details">
                        <img className="league-logo" src={require('./../../assets/images/leagues/6.png')} />
                    </div>
                </div>
            </div>
        )
    }

}

export default Leagues;