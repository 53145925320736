import React from "react";

const VideoCard = ({title, videoPreview, onClick}) => {
    return(
        <div className="video-card" onClick={onClick}>
            <div className="video-card-preview">
                <img src={videoPreview} alt={title} />
            </div>
            <div className="video-card-title">
                {title}
            </div>
        </div>
    )
}

export default VideoCard