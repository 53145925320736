import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import App from './App';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/configureStore';
// import * as serviceWorker from './serviceWorker';
// import history from './utils/history';

ReactDOM.render(
    <Provider store={store}>
        <Router >
            <Route component={App}/>
        </Router>
      </Provider>  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
