import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './formElements.scss';
import downArraow from './../../assets/images/icons/down-arrow.svg'
import upArraow from './../../assets/images/icons/up-arrow.svg'
import epl from './../../assets/images/leagues/epl.png'

class CustomDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            selectedItem: this.props.selectedItem
        }
        this.dropdownId = "custom-drodown-container";
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = event => {
        let dialogClick = false;
        //for mozilla
        //https://stackoverflow.com/questions/39245488/event-path-undefined-with-firefox-and-vue-js
        let path = event.path || (event.composedPath && event.composedPath());
        path.every(element => {
            if (element.id === this.dropdownId) {
                dialogClick = true;
                return false;
            }
            return true;
        })

        if (!dialogClick) { // it clicked on outside the dialog
            this.setState({
                listOpen: false
            },()=>{
                this.props.toggleDropDown(this.state.listOpen)
            })
        }
    }
    toggleList = () => {
        this.setState({
            listOpen: !this.state.listOpen
        },()=>{
            this.props.toggleDropDown(this.state.listOpen)
        })
    }

    selectItem = (data) => {
        this.props.selectItem(data)
        this.setState({
            selectedItem: { id: data.id, value: data.value, img: data.img },
            listOpen: false
        },()=>{
            this.props.toggleDropDown(this.state.listOpen)
        })
    }
    render() {
        const { customStyle, options, placeholder, noBorder } = this.props;
        const { listOpen, selectedItem } = this.state;
        return (
            <div className={`custom-dropdown-wrapper ${customStyle}`} id={this.dropdownId}>
                <div className={`${noBorder ? "noBorder" : "border"} dd-header`} onClick={this.toggleList}>
                    <div className="dd-header-title">
                        {selectedItem && selectedItem.value ?
                            <div className="selected-item">
                                <img src={selectedItem.id===524 ? epl: selectedItem.img} className="dd-list-item-img" />
                                <span className={noBorder ? "only-for-pc" :''}>{selectedItem.value.substring(0, selectedItem.value.lastIndexOf('-'))}</span>
                            </div>
                            : placeholder}
                        {listOpen ?
                            <img src={upArraow} className="dd-down-arrow-icon" alt="down-arrow" /> :
                            <img src={downArraow} className="dd-down-arrow-icon" alt="down-arrow" />}
                    </div>
                </div>
                {listOpen ?
                    <div className="dd-list">
                        {options.length > 0 ?
                            options.map((rowData, index) => {
                                return (
                                    <div key={rowData.value} onClick={() => this.selectItem(rowData)} className="dd-list-item"><img src={rowData.id===524 ? epl: rowData.img} className="dd-list-item-img" /> {rowData.value}</div>
                                )
                            }) : null
                        }
                    </div>
                    : null}
            </div>
        )
    }
}

CustomDropDown.defaultProps = {
    selectedItem: null,
    customStyle: "",
    noBorder: false,
    toggleDropDown:()=>{}
}

CustomDropDown.propTypes = {
    options: PropTypes.array,
    selectedItem: PropTypes.object,
    noBorder: PropTypes.bool,
    toggleDropDown:PropTypes.func
}

export default CustomDropDown;