export default function getSearchParams(param){
    const href = new URL(window.location.href);
    const q = href.searchParams.get(param);
    const h = getHashParams(param, href);
    if(q) return q;
    else if(h) return h;
    else return undefined;
}

function getHashParams(param, href){
    const u = new URL(window.location.href).hash.split('?')[1];
    const h = new URLSearchParams(u).get(param);
    return h;
}