import * as action_type from './../actions/leaderboard/action_types';
import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from './../../lib/api';


function* updatePredictionsPoints(action) {
    try {
        const data = yield API.updatePredictionsPoints(action.data);
        yield put({ type: action_type.UPDATE_PREDICTION_POINTS_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getLeadersPoints(action) {
    try {
        const data = yield API.getLeadersPoints();
        yield put({ type: action_type.GET_LEADERS_POINT_FETCH_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}

export const LeaderboardSaga = [
    takeEvery(action_type.UPDATE_PREDICTION_POINTS_POST, updatePredictionsPoints),
    takeEvery(action_type.GET_LEADERS_POINT_FETCH, getLeadersPoints),

]