import * as action from './action_types';

export function selectMenuItem(item) {
    return {
        type: action.SELECT_MENU_ITEM,
        item:item
    }
};

export function selectLanguage(lang) {
    return {
        type: action.SELECT_LANGUAGE,
        lang:lang
    }
};



