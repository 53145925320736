import uuidv1 from 'uuid/v1';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const sessionCookieAlive = () => {
    if (cookies.get("sessionId")) return true;
    else return false;
}

const generateSessionCookie = () => {
    let d = new Date();
    d.setTime(d.getTime() + (30 * 60 * 1000));
    let sessionId = uuidv1().replace(/-/g, '');
    cookies.set("sessionId", sessionId, { path: "/", expires: d });
    return true;
}

const getCurrentCookie = () => {
    return cookies.get('sessionId');
}

export const getSessionCookie = () => {
    if (!sessionCookieAlive()) generateSessionCookie();
    return getCurrentCookie();
}