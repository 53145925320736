import React , {Component} from 'react'
import PropTypes from 'prop-types';

import './loader.scss'


const Loader =({customStyle})=> {
        return(
            <div className="loader">
                <img className={`loader-img ${customStyle}`} src={require('./../../../assets/images/loader.svg')}/>
            </div>
        )
    }

export default Loader