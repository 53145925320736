import React ,{Component} from 'react';
import PropTypes from 'prop-types';

import './formElements.scss';

class SimpleInputBox extends Component {
    constructor(props){
        super(props);
        this.state={
            isVisible:false
        }
        this.textInput = React.createRef();
    }
    showInput=()=>{
        this.setState({
            isVisible:true
        },()=>{
            this.textInput.current.focus();
        })
    }
    render(){
        const {placeholder, disabled,imagePath, isDisabled,children,IconComponent,customStyle,value,inputPlaceHolder,...rest}=this.props;
        const {isVisible}=this.state;   
        return(
            <div className={`simple-input ${customStyle && customStyle} ${isVisible ? 'border-color' :'border-black'}`} onClick={this.showInput}>
                {isVisible || value!=='' ?
                    <input type="text"
                    placeholder={inputPlaceHolder}
                    ref={this.textInput}
                    value={value}
                     {...rest} />:
                    <div className="placeholder-conetnt">
                        {IconComponent && <IconComponent />}
                        <span className="placeholder-text">{placeholder}</span>
                    </div>
                }
   
            </div >
        )
    }
}

SimpleInputBox.defaultProps={
}

SimpleInputBox.propTypes = {
    placeholder: PropTypes.any,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    imagePath:PropTypes.string
}

export default SimpleInputBox;