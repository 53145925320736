import axios from 'axios';

export const apiRequest = async (method, featureURL,localApi=false, data = {}) => {
    let domain ,baseURL;
    if(localApi){
        let domainUrl = window.location.hostname.includes('localhost') ? 'http://localhost:3002' : window.location.origin;
        // let domainUrl = "http://localhost:3002";
        // let domainUrl = "http://localhost:3007";
        baseURL = domainUrl + '/api';

    }else{
        domain = "https://api-football-v1.p.rapidapi.com"
        baseURL = domain + '/v2';
    }
    let token;
    if (sessionStorage.getItem('token')) {
        token = JSON.parse(sessionStorage.getItem('token')).token;
    }
    // console.log(token);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': "a3c6f00666msh95bd2192c3910c4p1a692bjsnc257edbd1c6e",
        "authorization":token
    }
    return new Promise((resolve, reject) => {
        if (method === "get") {
            axios({
                method: 'get',
                url: baseURL + featureURL,
                headers: headers
            }).then(result => {
                resolve(result.data);
            }).catch(error => {
                if (error.message === 'Network Error') {
                    alert("No internet connection")
                }
                reject(error);
            })
        } else {
            axios({
                method: method,
                url: baseURL + featureURL,
                data: data,
                headers: headers
            }).then(result => {
                resolve(result.data);
            }).catch(error => {
                if (error.message === 'Network Error') {
                    alert("No internet connection")
                }
                reject(error);
            })

        }
    })

}

