export const POOL_RANKING_FETCH = 'POOL_RANKING_FETCH';
export const POOL_RANKING_SUCCESS = 'POOL_RANKING_SUCCESS';
export const POOL_RANKING_FAIL = 'POOL_RANKING_FAIL';

export const GET_LIVE_SCORE_FETCH = 'GET_LIVE_SCORE_FETCH';
export const GET_LIVE_SCORE_SUCCESS = 'GET_LIVE_SCORE_SUCCESS';
export const GET_LIVE_SCORE_FAIL = 'GET_LIVE_SCORE_FAIL';

export const GET_TEAM_STATS_FETCH = 'GET_TEAM_STATS_FETCH';
export const GET_TEAM_STATS_SUCCESS = 'GET_TEAM_STATS_SUCCESS';
export const GET_TEAM_STATS_FAIL = 'GET_TEAM_STATS_FAIL';

export const GET_TEAM_TRANSFERS_FETCH = 'GET_TEAM_TRANSFERS_FETCH';
export const GET_TEAM_TRANSFERS_SUCCESS = 'GET_TEAM_TRANSFERS_SUCCESS';
export const GET_TEAM_TRANSFERS_FAIL = 'GET_TEAM_TRANSFERS_FAIL';

export const GET_TEAM_PLAYERS_FETCH = 'GET_TEAM_PLAYERS_FETCH';
export const GET_TEAM_PLAYERS_SUCCESS = 'GET_TEAM_PLAYERS_SUCCESS';
export const GET_TEAM_PLAYERS_FAIL = 'GET_TEAM_PLAYERS_FAIL';

export const GET_PLAYER_BY_ID_FETCH = 'GET_PLAYER_BY_ID_FETCH';
export const GET_PLAYER_BY_ID_SUCCESS = 'GET_PLAYER_BY_ID_SUCCESS';
export const GET_PLAYER_BY_ID_FAIL = 'GET_PLAYER_BY_ID_FAIL';

export const SET_TIMER_TIME = 'SET_TIMER_TIME';


export const GET_TEAM_DETAILS_FETCH = 'GET_TEAM_DETAILS_FETCH';
export const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS';
export const GET_TEAM_DETAILS_FAIL = 'GET_TEAM_DETAILS_FAIL';