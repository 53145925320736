import * as apiconfig from './apiconfig';

const API = {
    getCountryList: () => {
        return apiconfig.apiRequest("get", `/countries`)
    }, 
    getGroupRanking: (id) => {
        return apiconfig.apiRequest("get", `/leagueTable/${id}`)
    },
    getMatchDetails: (id) => {
        return apiconfig.apiRequest("get", `/fixtures/league/${id}`)
    },
    checkUserAuth: (uid,type) => {
            return apiconfig.apiRequest("get", `/checkAccess?uid=${uid}&type=${type}&domain=${window.location.host.includes('localhost') ? 'uk.striker-live.com' : window.location.hostname}`,true)
    },
    checkUserAuthStaging: (uid) => {
        return apiconfig.apiRequest("get", `/checkAccessStaging?uid=${uid}&domain=${window.location.host.includes('localhost') ? 'za.striker-live.com' : window.location.hostname}`, true)
    },
    getUserDetails: () => {
        return apiconfig.apiRequest("get", `/getUserProfile`,true)
    },
    editProfile: (data) => {
        return apiconfig.apiRequest("put", `/editProfile`,true,data)
    },
    deleteUser: () => {
        return apiconfig.apiRequest("delete", `/deleteUser`,true)
    },
    addPrediction: (data) => {
        return apiconfig.apiRequest("post", `/addprediction`,true,data)
    },
    getPredictions: () => {
        return apiconfig.apiRequest("get", `/predictions`,true)
    },
     getLeaders: () => {
        return apiconfig.apiRequest("get", `/leaderboard`,true)
    },
    getLiveScore: () => {
        return apiconfig.apiRequest("get", `/fixtures/live`)
    },
    getLeaguesList: (season) => {
        return apiconfig.apiRequest("get", `/leagues/season/${season}`)
    },
    updatePredictionsPoints: (data) => {
        return apiconfig.apiRequest("post", `/updateprediction`,true,data)
    },
    getLeadersPoints: () => {
        return apiconfig.apiRequest("get", `/leaderboard`,true)
    },
    getTeamStats: (league_id,team_id) => {
        return apiconfig.apiRequest("get", `/statistics/${league_id}/${team_id}`)
    },
    getTeamTransfers: (team_id) => {
        return apiconfig.apiRequest("get", `/transfers/team/${team_id}`)
    },
    getTeamPlayers: (team_id,season) => {
        return apiconfig.apiRequest("get", `/players/team/${team_id}/${season}`)
    },
    getPlayersDetailsById: (player_id) => {
        return apiconfig.apiRequest("get", `/players/player/${player_id}`)
    },
    getTeamDetails: (team_id) => {
        return apiconfig.apiRequest("get", `/teams/team/${team_id}`)
    }
}


export default API;
