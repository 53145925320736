import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { RegistrationSaga } from './registrationSaga';
import { LivescoreSaga } from './livescoreSaga';
import { CalendarSaga } from './calendarSaga';
import { LeaderboardSaga } from './leaderboardSaga';

export default function* MainSaga() {
    try {
        yield all([
            ...RegistrationSaga,...LivescoreSaga,...CalendarSaga,...LeaderboardSaga])
    }
    catch (error) {
    }
}