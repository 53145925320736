import { getSubdomain } from '../utils/helper'

export const languages=[
    {value:"fr",label:"French"},
    {value:"en",label:"English"},
    {value:"ar",label:"Arabic"},
]

export const timerData={
    minutes:0,
    seconds:0,
    currentTimeTimer:"00:00"
}

export const ghSubscriptionInst = [
    {
        question: 'Why you subscribed with us',
        ans:"By subscribing to our Striker platform, you've unlocked a world of unbeatable value that enriches your sports journey. With every match update, livescore ping, and real-time insight into your favorite leagues, you're at the forefront of sports excitement. From meticulously curated standings to up-to-the-minute events, and even pre-match odds, you're equipped with a comprehensive toolkit for immersive sports engagement. By being a part of Striker, you're embracing a realm where statistics come alive, and every kick of the ball resonates with deeper meaning. Thank you for subscribing to Striker and allowing us to enhance your sports experience in ways beyond compare."
    },
    {
        question: 'For assistance, please reach out to us via',
        ans:"GH-sportsstream@cchelp.co"
    }
]
export let analyticsUrl = '';

if(getSubdomain().indexOf('local') == 0) analyticsUrl = "http://localhost:3000/posts";
else analyticsUrl = "https://de-pacman.sam-media.com/api/v2/mstore";
