//without text input
import React from 'react';
import PropTypes from 'prop-types';
import {Simplebutton,CustomDropdown,ErrorMessage} from './../form-elements';
import ComponentWrapper from './componentWrapper';
import { FormattedMessage } from 'react-intl';
 import loginWrapper from './loginHOC'

import Loader from './../core/loader/loader';
import './login.scss';

const Leagues = ({handleRouting,options,selectItem,fields,errors,isSaving}) =>{ 
    let selectedItem=fields['league_id']? 
    {
        id:fields['league_id'],
        value:fields['league_name'],
        img:fields['league_logo']
    }:'';
    return(
    <ComponentWrapper selectedImage={selectedItem.img} heading={<FormattedMessage id='Leagues' defaultMessage='League'/>} subheading={<FormattedMessage id='select_your_favorite_league' defaultMessage='Select your favorite league'/>}>
        <div className="login-form login-form-league-container">
            <CustomDropdown
             customStyle={'league-dd-wrapper'}
             selectItem={selectItem}
             options={options}
             selectedItem={selectedItem}
             placeholder={
                <FormattedMessage id='select_your_league' defaultMessage="Select your league"/>
            } 
             />
            {/* <SimpleDropDown
                type="text"
                name="team"
                value={defaultValue}
                onChange={handleChange}
                placeholder={
                    <FormattedMessage id='team_dropdown_placeholder' defaultMessage="Select your league"/>
                } 
                options={countryList}
                IconComponent={
                        ()=><img src={require('./../../assets/images/icons/pen.svg')} alt="edit-icon"/>
                    }
            /> */}
            <ErrorMessage errorMessage={errors['league']}/>
            <Simplebutton onClick={()=>handleRouting('/')} isDisabled={isSaving}
             text={isSaving ? null :<FormattedMessage id='continue' defaultMessage='Continue'/>} isPrimary={false} customStyle={'login-inputbox'}>
            {isSaving ? <Loader customStyle={'loader-size'}/>:   
                <img className="login-icon" src={require('./../../assets/images/icons/right-arrow.svg')} alt="login-icon"/>
                }
            </Simplebutton>
        </div>
    </ComponentWrapper>
)};

Leagues.defaultProps={
}

Leagues.propTypes = {
    handleRouting:PropTypes.func,
    handleChange:PropTypes.func,
    fields:PropTypes.array,
    errors:PropTypes.array,
    countryList:PropTypes.array,  
    selectItem:PropTypes.func 
}

export default Leagues;