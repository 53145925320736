import * as action_type from './../actions/calendar/action_types';
import { connect } from 'react-redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import API from './../../lib/api';
import {createPredictionPointData} from './../../utils/helper'


function* getMatchDetails(action) {
    try {
        const data = yield API.getMatchDetails(action.id);
        const predictedData = yield API.getPredictions();
        let predictedPointData=createPredictionPointData(data.api.fixtures,predictedData.data)
        const predictionPointdData = yield API.updatePredictionsPoints(predictedPointData);
        yield put({ type: action_type.GET_MATCH_DETAILS_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* addPrediction(action) {
    try {
        yield put({ type: action_type.ADD_PREDICTION,data: action.data,otherData:action.otherData })
        const data = yield API.addPrediction(action.data);
        yield put({ type: action_type.ADD_PREDICTION_SUCCESS, data })
    }
    catch (e) {
        console.log(e);
    }
}
function* getPredictions(action) {
    try {
        const data = yield API.getPredictions();
        yield put({ type: action_type.GET_PREDICTION_SUCCESS,data:data.data })
    }
    catch (e) {
        console.log(e);
    }
}

export const CalendarSaga = [
    takeEvery(action_type.GET_MATCH_DETAILS_FETCH, getMatchDetails),
    takeEvery(action_type.ADD_PREDICTION_POST, addPrediction),
    takeEvery(action_type.GET_PREDICTION_FETCH, getPredictions),

]