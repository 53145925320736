
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './../form-elements/datePicker';
import CalendarWrapper from './calendarHOC'
import { FormattedMessage } from 'react-intl';

import './calendar.scss';
import { formateDate } from './../../utils/helper';
import UpcomingMacthesCard from './upcomingMacthesCard';
import PreviousMatchCard from './previousMatchCard';


const Matches = ({selectedLanguage,handlePrediction,headerText,selectedDate,getSelectedDate,cardType,status,parseMatchesList,disabledDates,predicatedData}) => {
    let matcheData=cardType ==='previous' ? parseMatchesList(cardType).reverse():parseMatchesList(cardType);
    let mobileHeaderText=status==="NS" ?  <FormattedMessage id='upcoming_matches' defaultMessage='Upcoming Matches'/>    : <FormattedMessage id='previous_results' defaultMessage='Previous Results'/>

    return (
        <div className="matches-container">
            <div className="matches-header">
                <div>
                    <span className="matches-head">{cardType==="mobile"? mobileHeaderText : headerText}</span><br />
                    <span className="matches-subhead">
                        {selectedDate && `${selectedLanguage==='fr'? formateDate(selectedDate, 'DD MMMM,YYYY'):formateDate(selectedDate, 'dddd, MMMM Do')}`}
                    </span>
                </div>
                <div className="match-date-picker">
                    <DatePicker disabledDays={
                        disabledDates
                       }
                       locale={selectedLanguage}
                        getSelectedDate={getSelectedDate}
                        title={<FormattedMessage id='calendar-dropdown-title' defaultMessage='Select a date'/>}
                    />
                </div>
            </div>
            <div>
                <div className={`matches-content ${matcheData.length>0 ? 'align-items-start':'align-items-center'}`}>
                    {matcheData.length > 0 ?
                        matcheData.map((rowData, index) => {
                            let commonProps={
                                selectedLanguage,
                                matchDetails:rowData,
                                predicatedData:predicatedData
                            }
                            if(cardType==="mobile"){
                                return (
                                    status ==='FT' ? 
                                    <PreviousMatchCard {...commonProps}/>:
                                    <UpcomingMacthesCard {...commonProps} handlePrediction={handlePrediction}/>
                                )
                            }else{
                                return (
                                    cardType ==='previous' ? 
                                    <PreviousMatchCard {...commonProps} />:
                                    <UpcomingMacthesCard {...commonProps}  handlePrediction={handlePrediction}/>
                                )
                            }
                            
                        })
                        :
                        <div className="no-result-found">
                            <span>
                            <FormattedMessage id='calendar-no-result' defaultMessage='No result available for'/>&nbsp;{formateDate(selectedDate, 'MMMM Do')} :(</span>
                        </div>
                    }
                </div>
            </div>
        </div>

    )

}

Matches.propTypes = {
    selectedDate: PropTypes.any,
    headerText: PropTypes.string.isRequired,
    cardType:PropTypes.string,
    parseMatchesList:PropTypes.func,
    disabledDates:PropTypes.object,
    handlePrediction:PropTypes.func,
    
}
export default CalendarWrapper(Matches);
