import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';

import './formElements.scss';
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
];
const customStyles = {
    placeholder: (provided, state) => ({
        ...provided,
        color:"#1D1D1B",
        fontSize: "23px",
        marginLeft:'80px'

    }),
    // control: (provided, state) => ({
    //     ...provided,
    //     "&:focus":{
    //         borderColor: "yellow"
    //     },
    //     "&:hover":{
    //         borderColor: "yellow"
    //     },
    //     "&:active":{
    //         borderColor: "yellow"
    //     }
    // })

}
class SimpleDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const { placeholder, options,disabled, imagePath, isDisabled, children, IconComponent, customStyle, ...rest } = this.props;
        return (
            <div className="simple-dropdown">
                <Select
                    // value={selectedOption}
                    isSearchable={true}
                    options={options}
                    placeholder={placeholder}
                    styles={customStyles}
                    {...rest}
                />
            </div >
        )
    }
}

SimpleDropDown.defaultProps = {
}

SimpleDropDown.propTypes = {
    placeholder: PropTypes.any,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    imagePath: PropTypes.string
}

export default SimpleDropDown;