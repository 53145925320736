import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {isEmpty} from 'lodash'

import TeamDetailsWrapper from './teamDetailsWrapper';

const TeamStatistics = ({selectedTeamData,selectedLeage,teamStats}) => {

    return (
        !isEmpty(teamStats) ?
        <div className="team-statistics-container">
            <TeamDetailsWrapper heading={selectedLeage.value}>
            <div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='match-played' defaultMessage='Match Played' />
                </span>
                    <span className="team-details-item-value">{teamStats.matchs.matchsPlayed.total}</span>
                </div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='wins' defaultMessage='Wins' />
                    </span>
                    <span className="team-details-item-value">{teamStats.matchs.wins.total}</span>
                </div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='draws' defaultMessage='Draws' />
                    </span>
                    <span className="team-details-item-value">{teamStats.matchs.draws.total}</span>
                </div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='loses' defaultMessage='Loses' />
                    </span>
                    <span className="team-details-item-value">{teamStats.matchs.loses.total}</span>
                </div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='goals' defaultMessage='Goals' />
                    </span>
                    <span className="team-details-item-value">{teamStats.goals.goalsFor.total}</span>
                </div>
                <div className="team-details-item">
                    <span className="team-details-item-title">
                        <FormattedMessage id='goals-average' defaultMessage='Goals average' />
                    </span>
                    <span className="team-details-item-value">{teamStats.goalsAvg.goalsFor.total}</span>
                </div>
                {/* <div className="team-details-item">
                    <span className="team-details-item-title">Discipline</span>
                    <span className="team-details-item-value">38</span>
                </div> */}

            </div>
            </TeamDetailsWrapper>
        </div>:null
    )
}


TeamStatistics.defaultProps = {

}

TeamStatistics.propTypes = {
    selectedTeamData:PropTypes.object,
    selectedLeage:PropTypes.object,
    teamStats:PropTypes.object
}

export default TeamStatistics;