import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PlayerBasicDetails from './playerBasicDetails'
import {getPlayersDetailsById,getTeamDetails} from './../../redux/actions/livescore/action';
import PlayerStats from './PlayerStats';
import './playerDetails.scss';
import {parseQueryString} from './../../utils/helper'
import { sendData } from '../../analytics/sendData';

class PlayersDetails extends Component {
    componentWillMount(){
        const {params}=this.props.match;

        let playerId=params.playerId.indexOf('?')>=0? params.playerId.substring(0,params.playerId.indexOf('?')):params.playerId
        this.props.getTeamDetails(params.teamId);
        this.props.getPlayersDetailsById(playerId);
    }
    componentDidMount() {
        sendData('', '', 'football match' , 'viewed');
    }
    getPlayerData=(playerDetails)=>{
        const {params}=this.props.match;
        let playerData=playerDetails.length>0? playerDetails.filter((data)=>data.team_id==params.teamId && data.league==params.leagueName):[];
        return playerData;
    }
    render(){
        const {playerDetails,location,selectedLanguage,LivescoreReducer}=this.props;
        const {teamDetails}=LivescoreReducer
        let playerData=this.getPlayerData(playerDetails);
        return (
            playerData.length>0?
            <div className="player-details-container">
                    <div className="col-one">
                        <PlayerBasicDetails playerDetails={playerData[0]} 
                            teamLogo={teamDetails.logo} selectedLanguage={selectedLanguage}/>
                    </div>
                    <div className="col-two">
                        <PlayerStats playerDetails={playerData[0]}/>
                    </div>
            </div>:null
        )
    }
    
}




PlayersDetails.defaultProps = {

}

PlayersDetails.propTypes = {
}

const mapDispatchToProps = dispatch => {
    return {
        getPlayersDetailsById: bindActionCreators(getPlayersDetailsById, dispatch),
        getTeamDetails: bindActionCreators(getTeamDetails, dispatch),

    }
}

const mapStateToProps = state => {
    return {
        playerDetails: state.LivescoreReducer.playerDetails,
        selectedLanguage: state.HeaderReducer.selectedLanguage,
        LivescoreReducer: state.LivescoreReducer
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlayersDetails);