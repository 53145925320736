//without text input
import React from 'react';
import PropTypes from 'prop-types';

import './formElements.scss';

const SimpleButton = ({text, disabled,imagePath, isDisabled, isPrimary,children,customStyle,...rest}) => (
    <button
        className={`button ${isPrimary ? 'is-primary-button' : 'is-secondary-button'} ${customStyle && customStyle}`}
        disabled={isDisabled}
        {...rest}
    >
        {text && text}
        {children}
    </button>
);

SimpleButton.defaultProps={
    isPrimary: true
}

SimpleButton.propTypes = {
    text: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
    imagePath:PropTypes.string
}

export default SimpleButton;