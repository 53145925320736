import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import './livescore.scss';
import PropTypes from 'prop-types';



const MatchesResultButton = ({gotToCalendar}) => {
    return (
            <div className="matches-result-button" onClick={gotToCalendar}>
                    <span> <FormattedMessage id='match_results' defaultMessage='Matches Results'/></span>
                    <img className="matches-result-button-icon" src={require('./../../assets/images/icons/star-full-1.svg')} alt="star" />
            </div>
    )
}
MatchesResultButton.propTypes={
    gotToCalendar:PropTypes.func
}
export default MatchesResultButton;