import * as action from './action_types';

export function getMatchDetails(leauge_id) {
    return {
        type: action.GET_MATCH_DETAILS_FETCH,
        id:leauge_id
    }
};

export function addPrediction(data,otherData) {
    return {
        type: action.ADD_PREDICTION_POST,
        data:data,
        otherData
    }
};

export function getPredictions() {
    return {
        type: action.GET_PREDICTION_FETCH,
    }
};

export function closeModal() {
    return {
        type: action.CLOSE_MODAL,
    }
};

export function OpenModal() {
    return {
        type: action.OPEN_MODAL,
    }
};

