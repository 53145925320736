import React from 'react';
import { Route, Redirect } from "react-router-dom";


const PrivateRoute = ({ component: Component, ...rest }) => {

    let isLoggedIn = false;

    if (sessionStorage.getItem('loggedIn')) {
        isLoggedIn = JSON.parse(sessionStorage.getItem('loggedIn')).loggedIn;
    }
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn === true ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: '/welcome', state: { from: props.location } }} />

                    )
            }
        />)
};
export default PrivateRoute;
