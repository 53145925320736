import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './livescore.scss';
import history from './../../utils/history';
import { getGroupRanking ,getLiveScore,setLiveScore,setTimerTime,getTeamStats,getTeamPlayers,getTeamTransfers} from './../../redux/actions/livescore/action';
import { getMatchDetails } from './../../redux/actions/calendar/action';
import { selectMenuItem } from './../../redux/actions/header/action';
import RankingTableWrapper from './rankingTableWrapper';
import PlayNowCard from './playNowCard';
import LivescoreCard from './livescoreCard';
import MatchesResultButton from './matchesResultButton';
import { FormattedMessage } from 'react-intl';
import {appendUid,getLatestUpcomingMatch} from './../../utils/helper';
import {isEmpty} from 'lodash';
import API from './../../lib/api';
import {timerData} from './../../constants/common';
import TeamDetails from './../teamDetails/teamDetails'
import { sendData } from '../../analytics/sendData';


class Livescore extends Component {
    constructor(props){
        super(props);
        this.state={
            minutes:0,
            seconds:0,
            currentTime:'00:00',
            matchId:0,
            isTeamDetailsVisible:false,
            selectedTeamData:null
        }
        this.intervalId=0;
    }

    componentWillMount() {
        let allLeagues = this.props.RegistrationReducer.dropDownLeagueslist
        const {selectedLeage}  = this.props.RegistrationReducer
        allLeagues = allLeagues.filter((item) => {
            return Object.keys(item).length !== 0
        })
        let seletctedLeague=this.props.RegistrationReducer.selectedLeage.value;
        let initialId=this.props.RegistrationReducer.dropDownLeagueslist.filter(rowData=>{
            return rowData.value===seletctedLeague
        })
        if(initialId.length > 0){
            this.props.getGroupRanking(initialId[0].id);
            this.props.getMatchDetails(initialId[0].id);
        }
        else{
            this.props.getGroupRanking(selectedLeage.id);
            this.props.getMatchDetails(selectedLeage.id);
        }
        this.intervalId=setInterval(()=>{
            API.getLiveScore().then(data=>{
                if(data.api.results>0){
                    const {RegistrationReducer}=this.props
                    let LiveScoreData=data.api.fixtures.filter(rowData=>(rowData.league_id===RegistrationReducer.selectedLeage.id));
                    if(LiveScoreData.length>0){
                        this.props.setLiveScore(LiveScoreData[0])
                        this.matchTimer(LiveScoreData[0])
                    }
                  
                }
            })
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.intervalId);
    }
    
    componentDidMount() {
        sendData('', '', 'football match' , 'viewed');
    }

    matchTimer=(liveScore)=>{
        let {seconds,minutes} =timerData;
        if(liveScore.statusShort!=='HT' && liveScore.statusShort!=='BT' &&  liveScore.statusShort!=='INT' ){
            if(minutes===0 || liveScore.elapsed!==minutes){
                minutes=liveScore.elapsed;
                // this.setState({
                //     minutes,
                //     matchId:liveScore.fixture_id
                // })
                timerData.minutes=liveScore.elapsed;
                // this.props.setTimerTime({
                //     minutes,
                //     seconds,
                //     currentTimerTime
                // });
            }
                    seconds=seconds+1;
                    if(seconds===60){
                        minutes++;
                        seconds=0;
                    }
                    // this.setState({
                    //     minutes,
                    //     seconds,
                    // });
                    timerData.minutes=minutes;
                    timerData.seconds=seconds;
                    timerData.currentTimeTimer=`${minutes<10 ? '0'+minutes:minutes}:${seconds<10? '0'+seconds:seconds}`
                    // this.props.setTimerTime({
                    //     currentTimerTime:`${minutes<10 ? '0'+minutes:minutes}:${seconds<10? '0'+seconds:seconds}`,
                    //     minutes,
                    //     seconds
                    // });
                    // return `${minutes}:${seconds}`
            // return currentTime;
                }else{
                    // this.props.setTimerTime(liveScore.status);
                    timerData.currentTimeTimer=liveScore.status
                }
    }
    gotToCalendar=()=>{
        const {selectMenuItem,history,RegistrationReducer}=this.props;
        const {userDetails} =RegistrationReducer;
        history.push(appendUid('/calendar',userDetails.uid));
        selectMenuItem('calendar')
    }
    showTeamDetails=(groupData)=>{
        const {RegistrationReducer}=this.props;
        const {userDetails} =RegistrationReducer;
        // const leagueId= RegistrationReducer.selectedLeage.id
        // this.props.getTeamStats(leagueId,groupData.team_id);
        // this.props.getTeamTransfers(groupData.team_id);
        // this.props.getTeamPlayers(groupData.team_id,'2018');

        // this.setState({
        //     isTeamDetailsVisible:!this.state.isTeamDetailsVisible,
        //     selectedTeamData:groupData
        // })
        this.props.history.push(appendUid('/teamDetails/live/'+groupData.team_id,userDetails.uid));
    }
    toggleTeamDetails=()=>{
        this.setState({
            isTeamDetailsVisible:!this.state.isTeamDetailsVisible,
        })
    }
    render() {
        const { LivescoreReducer,RegistrationReducer,matchesList } = this.props;
        const {currentTime,isTeamDetailsVisible,selectedTeamData}=this.state
        const {userDetails,selectedLeage} =RegistrationReducer;
        let livescoreData=[];
        livescoreData=!isEmpty(LivescoreReducer.liveScore) ? LivescoreReducer.liveScore: getLatestUpcomingMatch(matchesList);
        let teamDetails={
            teamPlayers:LivescoreReducer.teamPlayers,
            teamStats:LivescoreReducer.teamStats,
            teamTransfers:LivescoreReducer.teamTransfers
        }
        return (
            <div className="live-score-container">
                <div className={`score-container ${!isEmpty(livescoreData)? 'score-container-bg':''}`}>
                    <div className="cards-container">
                      { !isEmpty(livescoreData) ? 
                      <LivescoreCard livescoreData={livescoreData}/>:
                      null
                    //   <div className="team-statistics-head">
                    //     <FormattedMessage id='team-stats' defaultMessage="Team Statistics"/>
                    //   </div>
                    }
                        {/* <PlayNowCard gotToCalendar={this.gotToCalendar}/> */}
                    </div>
                    <MatchesResultButton gotToCalendar={this.gotToCalendar}/>
                </div>
                {/* {
                    isTeamDetailsVisible? <TeamDetails {...teamDetails} selectedTeamData={selectedTeamData} selectedLeage={selectedLeage}
                     uid={userDetails.uid} toggleTeamDetails={this.toggleTeamDetails}/>: */}
                <div className="ranking-container">
                    <div  className="ranking-heading-container">
                        {/* <img className="ranking-headingicon" src={require('./../../assets/images/icons/medal-2.svg')} alt="medal"/> */}
                        <span className="ranking-heading"><FormattedMessage id='league_name' defaultMessage={selectedLeage.value}/></span>
                    </div>
                    <div className="ranking-table">
                         <RankingTableWrapper showTeamDetails={this.showTeamDetails} LivescoreReducer={LivescoreReducer}/>
                    </div>
                </div>
                {/* } */}
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        getGroupRanking: bindActionCreators(getGroupRanking, dispatch),
        selectMenuItem: bindActionCreators(selectMenuItem, dispatch),
        getLiveScore: bindActionCreators(getLiveScore, dispatch),
        getMatchDetails: bindActionCreators(getMatchDetails, dispatch),
        setLiveScore: bindActionCreators(setLiveScore, dispatch),
        setTimerTime: bindActionCreators(setTimerTime, dispatch),
    }
}

const mapStateToProps = state => {
    return {
        LivescoreReducer: state.LivescoreReducer,
        RegistrationReducer: state.RegistrationReducer,
        matchesList: state.CalendarReducer.matchesList,

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Livescore)
