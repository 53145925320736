import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './leaderboard.scss';
import history from './../../utils/history';
import { findIndex } from './../../utils/helper'
import { FormattedMessage } from 'react-intl';
import { getLeadersPoints } from './../../redux/actions/leaderboard/action'
import { sendData } from '../../analytics/sendData';

class Leaderboard extends Component {

    componentWillMount() {
        this.props.getLeadersPoints();
    }

    componentDidMount() {
        sendData('', '', 'football match' , 'viewed');
    }


    render() {
        const { LeaderBoardReducer } = this.props;

        return (
            <div className="leaderboard">
                <div className="leaderboard-head">
                    <img src={require('./../../assets/images/icons/trophy-2.svg')} className="trophy-icon only-for-pc" />
                    <img src={require('./../../assets/images/icons/trophy-white.svg')} className="trophy-icon only-for-mobile" />
                    <span className="leaderboard-title">
                    <FormattedMessage id='leaderboard' defaultMessage='Leaderboard' />
                    </span>
                </div>
                {LeaderBoardReducer.leaderboardData.length > 0 ?

                    <div className="leaderboard-data">
                        {LeaderBoardReducer.leaderboardData.map((rowData, index) => {
                            return(
                                <div className="user-points-container">
                                    <div className="user-points">   
                                        <div className="name-container">
                                            <span className="rank">{index+1}</span>
                                            <span className="name">{rowData.name}</span>
                                        </div>
                                        <div className="points-container">
                                            <span className="points">{rowData.total_point}</span>
                                            <img className="points-star" src={require('./../../assets/images/icons/star-line.svg')} />
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                </div>
                            )
                        })}


                    </div> :
                    <div className="empty-leaderboard">
                        <span>
                            <FormattedMessage id='leaderboard-empty' defaultMessage='Leaderboard will be available after the start of the season.' />
                        </span>
                    </div>
                }

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        LeaderBoardReducer: state.LeaderBoardReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLeadersPoints: bindActionCreators(getLeadersPoints, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
