import React , {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import history from './../../utils/history';

import './splashscreen.scss'
import {getCountryList} from './../../redux/actions/registration/action';
import { FormattedMessage } from 'react-intl';
import Loader from './../core/loader/loader';

class SplashScreen extends Component {

    componentWillMount(){
        console.log("SplashScreen",this.props)
        console.log('homepage========================');
    }


        // UNSAFE_componentWillReceiveProps(nextProps){
    //     console.log("nextProps,prevProps",nextProps,this.props);
    //     const {RegistrationReducer}=this.props;
    //     const nextData=nextProps.RegistrationReducer;
    //     const prevData=this.props.RegistrationReducer;
    //     if(nextData.accessData.success!==prevData.accessData.success){
    //         this.props.history.replace('login/nickname')
    //     }
    //   }

    render(){
        return(
            <div className="spash-screen">
                <img className="spash-screen-img" src={require('./../../assets/images/logo.svg')}/>
                <span className="spash-screen-title">
                    <FormattedMessage id='ultimate_online_football' defaultMessage='Ultimate online football'/>
                </span>
                <Loader/>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getCountryList: bindActionCreators(getCountryList, dispatch),

    }
}

const mapStateToProps = state => {
    return {
        RegistrationReducer: state.RegistrationReducer,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)