import React, { Component } from 'react';
import Modal from 'react-modal';
import './calendar.scss';
import {Simplebutton} from './../form-elements';
import UpcomingMacthesCard from './upcomingMacthesCard'
import { FormattedMessage } from 'react-intl';

const customStyles = {
    content : {
      top                   : '4%',
      left                  : '8%',
      right                 : '8%',
      bottom                : '4%',
      padding:'0'
    }
  };
  const matchData={
    "fixture_id":118077,
    "league_id":194,
    "event_date":"2017-03-22T15:30:00+00:00",
    "event_timestamp":1490196600,
    "firstHalfStart":1490196600,
    "secondHalfStart":1490200200,
    "round":"Preliminary Round",
    "status":"Match Finished",
    "statusShort":"FT",
    "elapsed":90,
    "venue":"Estádio Nacional 12 de Julho (São Tomé)",
    "referee":'',
    "homeTeam":{
        "team_id":1494,
        "team_name":"Djibouti",
        "logo":"https://www.api-football.com/public/teams/1535.png",
        },
    "awayTeam":{
        "team_id":1490,
        "team_name":"Madagascar",
        "logo":"https://www.api-football.com/public/teams/1490.png"
    },
    "goalsHomeTeam":0,
    "goalsAwayTeam":1
  }
  const predicatedData=[{
    matchDateTime: "2019-06-21T20:00:00+00:00",
    matchid: 118077,
    point: "0",
    pointId: 8,
    predicated_team_one_id: 1494,
    predicated_team_two_id: '',
    team_one_flag: "https://www.api-football.com/public/teams/32.png",
    team_one_id: 32,
    team_one_name: "Egypt",
    team_two_flag: "https://www.api-football.com/public/teams/1522.png",
    team_two_id: 1522,
    team_two_name: "Zimbabwe",
    updatedAt: "2019-06-23T10:03:44.000Z",
    userId: "bc11d8be-7fe9-4ebe-bb37-9d3085cb81b7",
    winning_team_id: '',
  }]
  const predicatedDataForDraw=[{
    matchDateTime: "2019-06-21T20:00:00+00:00",
    matchid: 118077,
    point: "0",
    pointId: 8,
    predicated_team_one_id: 1494,
    predicated_team_two_id: 1490,
    team_one_flag: "https://www.api-football.com/public/teams/32.png",
    team_one_id: 32,
    team_one_name: "Egypt",
    team_two_flag: "https://www.api-football.com/public/teams/1522.png",
    team_two_id: 1522,
    team_two_name: "Zimbabwe",
    updatedAt: "2019-06-23T10:03:44.000Z",
    userId: "bc11d8be-7fe9-4ebe-bb37-9d3085cb81b7",
    winning_team_id: '',
  }]
class CalendarModal extends Component {
    render(){
        const {modalIsOpen,selectedLanguage,closeModal}=this.props;
        return(
            <div>
            <Modal
            isOpen={modalIsOpen}
            // onRequestClose={closeModal}
            style={customStyles}
            className={ window.screen.width<=768 ? "modal-style":''}
            // contentLabel="Example Modal"
            >
        { window.screen.width<=768 ? 
            <div className="calendar-modal-container">
                <img onClick={closeModal} className="cancel" src={require('./../../assets/images/icons/cancel-1.svg')}/>
                <div className="section">
                    <div className="modal-head">   
                        <img className="success-image" src={require('./../../assets/images/icons/success-1.svg')}/>
                        <span className="modal-title">
                            <FormattedMessage id='calendar-modal-title' defaultMessage='How to predict on a match?'/><br/>
                            {/* <FormattedMessage id='calendar-modal-subtitle' defaultMessage=''/> */}
                        </span>
                    </div>
                </div>
                <div className="section calendar-container">
                    <div className="prediction-instruction calendar-container">
                        <UpcomingMacthesCard selectedLanguage={selectedLanguage} isEditable={false} matchDetails={matchData} predicatedData={predicatedData}/>
                        <span className="instructions">
                            <FormattedMessage id='one-team-prediction-instructions' defaultMessage='Check the upcoming matches and click on the winning team star’s to place your prediction.'/><br/>
                        </span>
                        <span className="pred-divider"></span>
                        <UpcomingMacthesCard selectedLanguage={selectedLanguage} isEditable={false} matchDetails={matchData} predicatedData={predicatedDataForDraw}/>
                        <span className="instructions">
                            <FormattedMessage id='draw-prediction-instructions' defaultMessage='Click on both stars to indicate a draw'/><br/>
                        </span>
                    </div>
                </div>
                            <div className="button-section">
                                <Simplebutton text={
                                    <FormattedMessage id='continue' defaultMessage="Continue" />
                                } customStyle={'button-style'} onClick={closeModal}>
                                    <img className="continue-icon" src={require('./../../assets/images/icons/arrow-right.svg')} alt="continue-icon" />
                                </Simplebutton>
                            </div>
            </div>:
            <div className="calendar-modal-container player-background">
                <div className="section-one">
                    {/* <img className="player-image" src={require('./../../assets/images/login-page-banner1.png')}/> */}
                </div>
                <div className="section-two">
                    <div className="header-section">
                        <img className="logo-image" src={require('./../../assets/images/logo.png')}/>
                        <span className="logo-title">
                            <FormattedMessage id='ultimate_online_football' defaultMessage='Ultimate online football'/>
                        </span>
                    </div>
                    <div className="modal-card">
                        <img onClick={closeModal} className="cancel" src={require('./../../assets/images/icons/cancel-1.svg')}/>
                        <div className="modal-card-content">
                            <img className="success-image" src={require('./../../assets/images/icons/success-1.svg')}/>
                            <div className="text-container">
                                <span><FormattedMessage id='predict_text' defaultMessage='Predict'/></span><br/>
                                <span className="winner-text">
                                    <FormattedMessage id='the-winner' defaultMessage='The Winner !'/>
                               </span><br/>
                                <span>
                                    <FormattedMessage id='Participate-and-join' defaultMessage='Participate, join the leaderbord and win trophies'/>
                                </span>
                            </div>
                        </div>
                        <div className="prediction-instructions-for-pc">
                            <div className="calendar-container">
                                <UpcomingMacthesCard selectedLanguage={selectedLanguage} isEditable={false} matchDetails={matchData} predicatedData={predicatedData}/>  
                                <span className="instructions-for-pc">
                                    <FormattedMessage id='prediction-instructions-for-pc' 
                                    defaultMessage='Check the upcoming matches and click on the winning team star’s to place your prediction.
                                    Click on both stars to indicate a draw'/>
                        
                                </span>
                            </div>
                            <div className="button-section">
                                <Simplebutton text={
                                    <FormattedMessage id='continue' defaultMessage="Continue"/>
                                 } customStyle={'button-style'} onClick={closeModal}>
                                    <img className="continue-icon" src={require('./../../assets/images/icons/arrow-right.svg')} alt="continue-icon"/>
                                </Simplebutton>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        }
            </Modal>
      </div>
        )
    }
}
export default CalendarModal