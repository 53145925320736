import React , {Component} from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { selectLanguage } from './../../redux/actions/header/action';

import {getCountryList,editProfile,checkUserAuth,getUserDetails,changeLeague} from './../../redux/actions/registration/action';

function loginWrapper(ComposedClass){
    class BaseClass extends Component{

        constructor(props){
            super(props)
            this.state = {
                isFetching: false
            }
        }
       
        componentWillMount(){
        }

        render(){
            const {RegistrationReducer } =this.props;
            const {countryList} =RegistrationReducer;
            let teamList=[];
            countryList.length && countryList.map((rowData,index)=>{
                let country=rowData.country
                teamList[index]={value:country,label:country}
            })
            return <ComposedClass {...this.props} teamList={teamList} onFailure={this.onFailure} isFetching={this.state.isFetching}/>
        }
    }
    BaseClass.propTypes = {
       
    }
    const mapDispatchToProps = dispatch => {
        return{
            getCountryList: bindActionCreators(getCountryList, dispatch),
            editProfile: bindActionCreators(editProfile, dispatch),
            checkUserAuth: bindActionCreators(checkUserAuth, dispatch),
            getUserDetails: bindActionCreators(getUserDetails, dispatch),
            selectLanguage: bindActionCreators(selectLanguage, dispatch),
            changeLeague: bindActionCreators(changeLeague, dispatch),
           
        }
    }
    
    const mapStateToProps = state => {
        return{
            RegistrationReducer: state.RegistrationReducer,
            selectedLanguage: state.HeaderReducer.selectedLanguage
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(BaseClass)
}

export default loginWrapper;