import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getPredictions,addPrediction } from './../../redux/actions/calendar/action';

import {formateDate} from './../../utils/helper'
import { CalendarSaga } from '../../redux/redux-saga/calendarSaga';
import { sendData } from '../../analytics/sendData';

function CalendarWrapper(ComposedClass){

class CalendarHOC extends Component {
    constructor(props){
        super(props)
        this.state={
            selectedDate:null,
            status:'NS'
        }
    }
    componentWillMount(){
        this.props.getPredictions();
    }
    getSelectedDate=(selectedDate)=>{
        let status= (new Date(selectedDate)).getTime()<=(new Date()).getTime() ? 'FT' : 'NS';

        this.setState({
            selectedDate:selectedDate,
            status
        })
    }
    handlePrediction=(matchData,predicated_id,isHomeTeamPredicted,isAwayTeamPredicted)=>{
        const {addPrediction} =this.props;
       let predictionObj= {
            "matchid" : matchData.fixture_id,
            "team_one_name" : matchData.homeTeam.team_name,
            "team_one_id" : matchData.homeTeam.team_id,
            "team_one_flag" : matchData.homeTeam.logo,
            "team_two_name" :  matchData.awayTeam.team_name,
            "team_two_id" : matchData.awayTeam.team_id,
            "team_two_flag" : matchData.awayTeam.logo,
            "predicated_team_one_id" : isHomeTeamPredicted ? matchData.homeTeam.team_id:predicated_id===matchData.homeTeam.team_id ? predicated_id: null,
            "predicated_team_two_id" : isAwayTeamPredicted ? matchData.awayTeam.team_id:predicated_id===matchData.awayTeam.team_id ? predicated_id:null,
            "matchDateTime" :matchData.event_date,
            "predicated_id":predicated_id
            }
            let otherData={
                predicated_id,
                isAwayTeamPredicted,
                isHomeTeamPredicted
            }
            addPrediction(predictionObj,otherData);
            sendData('', '', 'football match' , 'predicted');
    }
    parseMatchesList=(cardType)=>{
        const {matchesList} =this.props;
        const {selectedDate,status} =this.state;
        let parsedMatchesLsit;
        if(selectedDate===null){
            let matchStatus=cardType === "upcoming" || cardType === "mobile" ?"NS" :"FT"
            parsedMatchesLsit=matchesList.filter((data)=>{
                return (data.statusShort === matchStatus)
            })
        }else{
            parsedMatchesLsit=matchesList.filter((data)=>{
                return (formateDate(data.event_date,'DD-MM-YYYY')===formateDate(selectedDate,'DD-MM-YYYY') && data.statusShort === status)
            })
        }
        return parsedMatchesLsit
    }
   
    render() {
        const {selectedDate,status} =this.state;
        const {CalendarReducer} =this.props;
        return <ComposedClass {...this.props} predicatedData={CalendarReducer.predictedData} handlePrediction={this.handlePrediction} selectedDate={selectedDate} status={status} parseMatchesList={this.parseMatchesList} getSelectedDate={this.getSelectedDate}/>
    }

}

CalendarHOC.prototypes={
    activeMenu:PropTypes.string,
    selectMenuItem:PropTypes.func
}

const mapStateToProps = state => {
    return {
        matchesList: state.CalendarReducer.matchesList,
        CalendarReducer: state.CalendarReducer,
        selectedLanguage: state.HeaderReducer.selectedLanguage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getPredictions: bindActionCreators(getPredictions, dispatch),
        addPrediction: bindActionCreators(addPrediction, dispatch),

    }
}
return  connect(mapStateToProps,mapDispatchToProps)(CalendarHOC);
}
export default CalendarWrapper;