import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Slider from "react-slick";


const PlayerStats = ({ playerDetails }) => {
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        centerMode: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows:false,
        variableWidth:true
    };
    return (
        window.screen.width <= 768 ?
            <div className="players-stats-mobile">
                <h2 className="player-stats-head">
                    <FormattedMessage id='player-stats' defaultMessage='Player Statistics' />
                </h2>
                <Slider {...settings}>
                    <div className="slider-card">
                        <div className="stats-cat">
                            <div className="player-stats-content-head">
                                <img className="icon" src={require('./../../assets/images/icons/swords-white.svg')} />
                                <span className="title-mobile">
                                    <FormattedMessage id='offensive' defaultMessage='Offensive' />
                                </span>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span>
                                            <FormattedMessage id='shots' defaultMessage='Shots' />
                                        </span>
                                    </div>
                                    <div className="col-two  item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='on' defaultMessage='On' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='assist' defaultMessage='Assist' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.shots.total}</span>
                                        <span className="box">{playerDetails.shots.on}</span>
                                        <span className="box">-</span>
                                        <span className="box highlight">{playerDetails.shots.total != 0 ? (playerDetails.shots.on / playerDetails.shots.total * 100).toFixed(2) : '0'}%</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='goals' defaultMessage='Goals' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='on' defaultMessage='On' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='assist' defaultMessage='Assist' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.goals.total}</span>
                                        <span className="box">-</span>
                                        <span className="box">{playerDetails.goals.assists}</span>
                                        <span className="box highlight">{playerDetails.goals.total != 0 ? (playerDetails.goals.assists / playerDetails.goals.total * 100).toFixed(2) : '0'}%</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span>
                                            <FormattedMessage id='passes' defaultMessage='Passes' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                         <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='on' defaultMessage='On' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='assist' defaultMessage='Assist' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.passes.total}</span>
                                        <span className="box">-</span>
                                        <span className="box">-</span>
                                        <span className="box highlight">{playerDetails.passes.accuracy}%</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-card">
                        <div className="stats-cat">
                            <div className="player-stats-content-head">
                                <img className="icon" src={require('./../../assets/images/icons/shield-white.svg')} />
                                <span className="title-mobile">
                                    <FormattedMessage id='defensive' defaultMessage='Defensive' />
                                </span>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span>
                                            <FormattedMessage id='tackles' defaultMessage='Tackles' />
                                        </span>
                                    </div>
                                    <div className="col-two  item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='blocks' defaultMessage='Blocks' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='won' defaultMessage='Won' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.tackles.total}</span>
                                        <span className="box">{playerDetails.tackles.blocks}</span>
                                        <span className="box">-</span>
                                        <span className="box highlight">{playerDetails.tackles.total != 0 ? (playerDetails.tackles.blocks / playerDetails.tackles.total * 100).toFixed(2) : '0'}%</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='duels' defaultMessage='Duels' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='blocks' defaultMessage='Blocks' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='won' defaultMessage='Won' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.duels.total}</span>
                                        <span className="box">-</span>
                                        <span className="box">{playerDetails.duels.won}</span>
                                        <span className="box highlight">{playerDetails.duels.total != 0 ? (playerDetails.duels.won / playerDetails.duels.total * 100).toFixed(2) : '0'}%</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='dribbles' defaultMessage='Dribbles' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='blocks' defaultMessage='Blocks' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='won' defaultMessage='Won' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.dribbles.attempts}</span>
                                        <span className="box">-</span>
                                        <span className="box">{playerDetails.dribbles.success}</span>
                                        <span className="box highlight">{playerDetails.dribbles.attempts != 0 ? (playerDetails.dribbles.success / playerDetails.dribbles.attempts * 100).toFixed(2) : '0'}%</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-card">
                        <div className="stats-cat">
                            <div className="player-stats-content-head">
                                <img className="icon" src={require('./../../assets/images/icons/card-white.svg')} />
                                <span className="title-mobile">
                                    <FormattedMessage id='discipline' defaultMessage='Discipline' />
                                </span>
                            </div>
                            <div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='cards' defaultMessage='Cards' />
                                        </span>
                                    </div>
                                    <div className="col-two  item-row">
                                        <span className="head">
                                            <FormattedMessage id='yellow' defaultMessage='Yellow' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='red' defaultMessage='Red' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.cards.yellow}</span>
                                        <span className="box">{playerDetails.cards.red}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='fouls' defaultMessage='Fouls' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="head">
                                            <FormattedMessage id='drawn' defaultMessage='Drawn' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='commit' defaultMessage='Commit' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.fouls.drawn}</span>
                                        <span className="box">{playerDetails.fouls.committed}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-one item">
                                        <span >
                                            <FormattedMessage id='games-played' defaultMessage='Games played' />
                                        </span>
                                    </div>
                                    <div className="col-two  item-row">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='min' defaultMessage='Min' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='sub-in' defaultMessage='Sub in' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='sub-out' defaultMessage='Sub out' />
                                        </span>
                                    </div>
                                    <div className="col-two item-row">
                                        <span className="box">{playerDetails.games.appearences}</span>
                                        <span className="box">{playerDetails.games.minutes_played}</span>
                                        <span className="box">{playerDetails.substitutes.in}</span>
                                        <span className="box">{playerDetails.substitutes.out}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

            :
            <div className="players-stats">
                <div className="player-stats-head">
                    <span>
                        <FormattedMessage id='player-stats' defaultMessage='Player Statistics' />
                    </span>
                </div>
                <div className="player-stats-content">
                    <div className="stats-cat">
                        <div className="player-stats-content-head">
                            <img className="icon" src={require('./../../assets/images/icons/swords.svg')} />
                            <span className="title">
                                <FormattedMessage id='offensive' defaultMessage='Offensive' />
                            </span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-one"></div>
                                <div className="col-two">
                                    <span className="head">
                                        <FormattedMessage id='total' defaultMessage='Total' />
                                    </span>
                                    <span className="head">
                                        <FormattedMessage id='blocks' defaultMessage='Blocks' />
                                    </span>
                                    <span className="head">
                                        <FormattedMessage id='won' defaultMessage='Won' />
                                    </span>
                                    <span className="head">
                                        <FormattedMessage id='success' defaultMessage='Success' />%
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <span ><FormattedMessage id='shots' defaultMessage='Shots' /></span>
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.shots.total}</span>
                                    <span className="box">{playerDetails.shots.on}</span>
                                    <span className="box">-</span>
                                    <span className="box highlight">{playerDetails.shots.total != 0 ? (playerDetails.shots.on / playerDetails.shots.total * 100).toFixed(2) : '0'}%</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <span ><FormattedMessage id='goals' defaultMessage='Goals' /></span>
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.goals.total}</span>
                                    <span className="box">-</span>
                                    <span className="box">{playerDetails.goals.assists}</span>
                                    <span className="box highlight">{playerDetails.goals.total != 0 ? (playerDetails.goals.assists / playerDetails.goals.total * 100).toFixed(2) : '0'}%</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <span ><FormattedMessage id='passes' defaultMessage='Passes' /></span>
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.passes.total}</span>
                                    <span className="box">-</span>
                                    <span className="box">-</span>
                                    <span className="box highlight">{playerDetails.passes.accuracy}%</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="stats-cat">
                        <div className="player-stats-content-head">
                            <img className="icon" src={require('./../../assets/images/icons/shield.svg')} />
                            <span className="title">
                                <FormattedMessage id='defensive' defaultMessage='Defensive' />
                            </span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-one"></div>
                                <div className="col-two">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='blocks' defaultMessage='Blocks' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='won' defaultMessage='Won' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='success' defaultMessage='Success' />%
                                        </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <FormattedMessage id='tackles' defaultMessage='Tackles' />
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.tackles.total}</span>
                                    <span className="box">{playerDetails.tackles.blocks}</span>
                                    <span className="box">-</span>
                                    <span className="box highlight">{playerDetails.tackles.total != 0 ? (playerDetails.tackles.blocks / playerDetails.tackles.total * 100).toFixed(2) : '0'}%</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <FormattedMessage id='duels' defaultMessage='Duels' />
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.duels.total}</span>
                                    <span className="box">-</span>
                                    <span className="box">{playerDetails.duels.won}</span>
                                    <span className="box highlight">{playerDetails.duels.total != 0 ? (playerDetails.duels.won / playerDetails.duels.total * 100).toFixed(2) : '0'}%</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <FormattedMessage id='dribbles' defaultMessage='Dribbles' />
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.dribbles.attempts}</span>
                                    <span className="box">-</span>
                                    <span className="box">{playerDetails.dribbles.success}</span>
                                    <span className="box highlight">{playerDetails.dribbles.attempts != 0 ? (playerDetails.dribbles.success / playerDetails.dribbles.attempts * 100).toFixed(2) : '0'}%</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="stats-cat">
                        <div className="player-stats-content-head">
                            <img className="icon" src={require('./../../assets/images/icons/card.svg')} />
                            <span className="title"> <FormattedMessage id='discipline' defaultMessage='Discipline' /></span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-one"></div>
                                <div className="col-two">
                                    <span className="head"><FormattedMessage id='yellow' defaultMessage='Yellow' /></span>
                                    <span className="head"><FormattedMessage id='red' defaultMessage='Red' /></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <span ><FormattedMessage id='cards' defaultMessage='Cards' /></span>
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.cards.yellow}</span>
                                    <span className="box">{playerDetails.cards.red}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one"></div>
                                <div className="col-two">
                                    <span className="head"> <FormattedMessage id='drawn' defaultMessage='Drawn' /></span>
                                    <span className="head"><FormattedMessage id='commit' defaultMessage='Commit' /></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <span ><FormattedMessage id='fouls' defaultMessage='Fouls' /></span>
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.fouls.drawn}</span>
                                    <span className="box">{playerDetails.fouls.committed}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="stats-cat">
                        <div className="player-stats-content-head">
                            <img className="icon" src={require('./../../assets/images/icons/football.svg')} />
                            <span className="title">
                                <FormattedMessage id='games' defaultMessage='Games' />
                            </span>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-one"></div>
                                <div className="col-two">
                                        <span className="head">
                                            <FormattedMessage id='total' defaultMessage='Total' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='min' defaultMessage='Min' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='sub-in' defaultMessage='Sub in' />
                                        </span>
                                        <span className="head">
                                            <FormattedMessage id='sub-out' defaultMessage='Sub out' />
                                        </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-one item">
                                    <FormattedMessage id='games-played' defaultMessage='Games played' />
                                </div>
                                <div className="col-two">
                                    <span className="box">{playerDetails.games.appearences}</span>
                                    <span className="box">{playerDetails.games.minutes_played}</span>
                                    <span className="box">{playerDetails.substitutes.in}</span>
                                    <span className="box">{playerDetails.substitutes.out}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    )
}


PlayerStats.defaultProps = {

}

PlayerStats.propTypes = {
    playerDetails: PropTypes.object
}

export default PlayerStats;