import * as action_type from './../actions/registration/action_types';
import { parseCountryData, parseUserDetails, parseLeagueData } from './../../utils/dataParser'
const initialState = {
    countryList: [],
    isFetching: false,
    accessData: {
        success: false
    },
    userDetails: {
    },
    leaguesList: [],
    dropDownLeagueslist: [],
    selectedLeage: {}
}
const RegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case action_type.GET_COUNTRY_LIST_SUCCESS:
            return Object.assign({}, state, {
                countryList: parseCountryData(action.data.api.countries)
            });
        case action_type.GET_USER_ACCESS_FETCH:
            return Object.assign({}, state, {
                isFetching: true
            });
        case action_type.GET_USER_ACCESS_SUCCESS:
            let userData = action.data.userDetails
            return Object.assign({}, state, {
                accessData: action.data,
                userDetails: parseUserDetails(userData),
                isFetching: false
            });
        case action_type.EDIT_USER_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                userDetails: action.data
            });
        case action_type.GET_USER_DETAILS_SUCCESS:
            let userDetails = parseUserDetails(action.data.data)
            return Object.assign({}, state, {
                userDetails: userDetails,
                selectedLeage: {
                    id: userDetails.league_id,
                    value: userDetails.league_name,
                    img: userDetails.league_logo
                }
            })
        case action_type.GET_LEAGUE_LIST_SUCCESS:
            return Object.assign({}, state, {
                leaguesList: parseLeagueData(action.data.api.leagues),
                dropDownLeagueslist: parseLeagueData(action.data.api.leagues).allLeagues,
                selectedLeage:parseLeagueData(action.data.api.leagues).defaultSelectedLeague
            });
        case action_type.CHANGE_LEAGUE:
            return Object.assign({}, state, {
                selectedLeage: action.data
            });
        default:
            return state;
    }
}

export default RegistrationReducer;